import { Component, OnInit } from '@angular/core';
import { ReviewTotal } from 'src/app/core/models/review-total.model';
import { ReviewService } from 'src/app/core/services/review.service';

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss']
})
export class CountersComponent implements OnInit {

  statistics: ReviewTotal = {
    reviews: {
      this_month: 0,
      positive: 0,
      neutral: 0,
      negative: 0,
      overall: 0,
      responded: 0
    },
    last_sync: new Date(),
    alerts: [],
    nps: {
      csat: 0,
      nps: 0,
      detractors: 0
    }
  }

  constructor(
    private reviewService: ReviewService 
  ) { }

  ngOnInit(): void {
    this.reviewService.getReviewsTotals().subscribe(data => {
      this.statistics = data;
      this.statistics.last_sync = new Date(data.last_sync);
    })
  }

}
