import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { TourOperatorService } from 'src/app/core/services/tour-operator.service';
import { TourOperator } from 'src/app/models/tour-operator';
import { SnackbarType } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'app-tour-operators-create-popup',
  templateUrl: './tour-operators-create-popup.component.html',
  styleUrls: ['./tour-operators-create-popup.component.scss'],
})
export class TourOperatorsCreatePopupComponent implements OnInit {
  loading = false;
  responded = false;
  errorDuringResponse = false;

  createTourOperatorForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl('', { validators: [Validators.required] }),
  });

  constructor(
    public dialogRef: MatDialogRef<TourOperatorsCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tourOperator?: TourOperator; },
    private tourOperatorService: TourOperatorService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {
    if (data.tourOperator) {
      this.createTourOperatorForm.get('id')?.setValue(data.tourOperator.id);
      this.createTourOperatorForm.get('name')?.setValue(data.tourOperator.name);
    }
  }

  ngOnInit(): void { }

  createTourOperator(): void {
    this.createTourOperatorForm.markAllAsTouched();
    if (this.createTourOperatorForm.invalid) return;

    this.loading = true;

    if (this.data.tourOperator) {
      this.tourOperatorService
        .updateTourOperator(this.createTourOperatorForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: tourOperator => {
            this.dialogRef.close(tourOperator);
            this.snackBarService.openSnackBar("Tour Operator Updated", "Tour Operator Updated Successully", SnackbarType.Success);
          },
        });
    }
    else {
      this.tourOperatorService
        .createTourOperator(this.createTourOperatorForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: tourOperator => {
            this.dialogRef.close(tourOperator);
            this.snackBarService.openSnackBar("Tour Operator Created", "Tour Operator Created Successully", SnackbarType.Success);
          },
        });
    }
  }

  closePopup() {
    this.dialog.closeAll();
  }
}
