import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Schedule } from 'src/app/models/schedule';
import { ScheduleCreate } from 'src/app/models/schedule-create';
import { ScheduleUpdate } from 'src/app/models/schedule-update';
import { environment } from 'src/environments/environment';


const API_EXPORTS_SCHEDULES: string = environment.baseLink + '/v1/api/exportschedules/';

@Injectable({
  providedIn: 'root'
})
export class ExportScheduleService {

  constructor (private http: HttpClient) { }


  exportSchedules(): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(API_EXPORTS_SCHEDULES);
  }

  createSchedule(data: ScheduleCreate): Observable<any> {
    return this.http.post(API_EXPORTS_SCHEDULES, data);
  }

  updateSchedule(data: ScheduleUpdate): Observable<any> {
    return this.http.put(`${API_EXPORTS_SCHEDULES}${data.id}/`, data);
  }

  deleteSchedule(id: number): Observable<any> {
    return this.http.delete(`${API_EXPORTS_SCHEDULES}${id}/`);
  }
}
