import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggedInUser } from '../models/logged-in-user.model';
import { PaginationResponse } from 'src/app/models/pagination-response';
import { UserCreate } from 'src/app/models/user-create';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';

const API_USER_INFO: string = environment.baseLink + '/v1/auth/userinfo/';
const API_TIMEZONES: string =
  environment.baseLink + '/v1/api/settings/timezone/get_timezone/';
const API_CHANGE_PASSWD: string =
  environment.baseLink + '/v1/auth/change-password/';
const API_PROF_PIC: string = environment.baseLink + '/v1/auth/profile-image/';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private router: Router) {}

  getUserInfo(): Observable<LoggedInUser> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .get<LoggedInUser>(API_USER_INFO, { headers: httpHeaders })
      .pipe(
        map((res: LoggedInUser) => {
          return res;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate(['/auth/login']);
          }

          return [null];
        })
      );
  }

  getTimeZones(): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .get<String[]>(API_TIMEZONES, { headers: httpHeaders })
      .pipe(
        map((res: String[]) => {
          return res;
        }),
        catchError((err) => {
          // TODO better error handling
          return [null];
        })
      );
  }

  updateUserInfo(user: LoggedInUser) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .put<any>(API_USER_INFO, user, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => {
          return [null];
        })
      );
  }

  changePasswd(passwdObj: {
    current_password: any;
    new_password: any;
    confirm_password: any;
  }) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post<any>(API_CHANGE_PASSWD, passwdObj, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => {
          return [null];
        })
      );
  }

  postProfPic(photoToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    let httpHeaders = new HttpHeaders();
    //httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    formData.append('profile_image', photoToUpload, photoToUpload.name);
    console.log(formData.get('profile_image'));
    return this.http
      .post(API_PROF_PIC, formData, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err) => {
          return null;
        })
      );
  }

  getUsers(): Observable<PaginationResponse<User>> {
    return this.http.get<PaginationResponse<User>>(
      environment.baseLink + '/v1/api/users/'
    );
  }

  createUser(user: UserCreate): Observable<User> {
    return this.http.post<User>(environment.baseLink + '/v1/api/users/', user);
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(
      `${environment.baseLink}/v1/api/users/${user.id}/`,
      user
    );
  }

  deleteUser(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${environment.baseLink}/v1/api/users/${id}/`);
  }
}
