import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ScheduleCreatePopupComponent } from '../schedule-create-popup/schedule-create-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleDeletePopupComponent } from '../schedule-delete-popup/schedule-delete-popup.component';
import { ExportScheduleService } from 'src/app/core/services/export-schedule.service';
import { forkJoin } from 'rxjs';
import { ExportEmailService } from 'src/app/core/services/export-email.service';
import { Email } from 'src/app/models/email';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit, AfterViewInit {

  emailList?: Email[];

  displayedColumns: string[] = ["name", "emails", "search_factor", "format", "date_range_export", "schedule", "actions"];

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;


  constructor (
    private scheduleService: ExportScheduleService,
    private exportEmailService: ExportEmailService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getExportSchedules();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getExportSchedules(): void {

    forkJoin([
      this.scheduleService.exportSchedules(),
      this.exportEmailService.exportEmails()
    ]).subscribe({
      next: response => {
        this.dataSource.data = response[0];
        this.emailList = response[1];
      }
    });
  }

  openScheduleCreatePopup(schedule?: any): void {
    this.dialog.open(ScheduleCreatePopupComponent, {
      data: {
        schedule
      },
      width: '700px',
    })
      .afterClosed()
      .subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getExportSchedules();
          }
        }
      });
  }

  openScheduleDeletePopup(schedule: any): void {
    this.dialog.open(ScheduleDeletePopupComponent, {
      data: {
        schedule
      },
      width: '700px',
    })
      .afterClosed().subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getExportSchedules();
          }
        }
      });
  }

  getEmailListById(emailIds: number[]): string {
    const emailList = this.emailList?.filter(email => emailIds.includes(email.id));
    return emailList?.map(email => email.email).join(", ");
  }

  getSchedule(schedule: string): string {
    const scheduleList = schedule.split(" ");
    let minutes = scheduleList[0];
    if (minutes.length < 2) {
      minutes = "0" + minutes;
    }
    let hours = scheduleList[1];
    if (hours.length < 2) {
      hours = "0" + hours;
    }

    let dayOfMonth: string = "";
    if (scheduleList[2] !== "*") {
      dayOfMonth = "Every month on day " + scheduleList[2];
    }

    let days: string = "";
    if (scheduleList[4] === "1-5") {
      days = "Every week from Monday to Friday";
    }
    else if (Number(scheduleList[4])) {
      if (scheduleList[4] === "1") {
        days = "Every week on Monday";
      }
      else if (scheduleList[4] === "2") {
        days = "Every week on Tuesday";
      }
      else if (scheduleList[4] === "3") {
        days = "Every week on Wednesday";
      }
      else if (scheduleList[4] === "4") {
        days = "Every week on Thursday";
      }
      else if (scheduleList[4] === "5") {
        days = "Every week on Friday";
      }
      else if (scheduleList[4] === "6") {
        days = "Every week on Saturday";
      }
      else if (scheduleList[4] === "7") {
        days = "Every week on Sunday";
      }
    }

    return dayOfMonth + " " + days + " at " + hours + ":" + minutes;
  }

}
