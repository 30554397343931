import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ClientService } from 'src/app/core/services/client.service';
import { UserRole } from 'src/app/enums/user-role';
import { UserType } from 'src/app/enums/user-type';
import { Client } from 'src/app/models/client';
import { ClientCreateComponent } from '../client-create-popup/client-create-popup.component';
import { ClientDeletePopupComponent } from '../client-delete-popup/client-delete-popup.component';
import { ClientUpdatePasswordPopupComponent } from '../client-update-password-popup/client-update-password-popup.component';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ["name", "email", "role", "actions"];

  dataSource = new MatTableDataSource<Client>();

  @ViewChild(MatTable) table: MatTable<Client>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  UserRole = UserRole;
  UserType = UserType;

  searchControl = new FormControl();

  page: number = 1;
  results = []
  loading = true


  searchFromGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
  });

  constructor (
    private clientService: ClientService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe({
      next: query => {
        if (query.page) {
          if (query.page > 0) {
            this.page = query.page;
          }
          else {
            this.router.navigate(["."], { relativeTo: this.route, queryParams: { page: 1 } });
            return;
          }
        }
        else {
          this.page = 1;
        }

        this.getClients();
      }
    });

    // this.searchControl.valueChanges.subscribe({
    //   next: value => {
    //     this.getClients(value);
    //   }
    // });

    this.searchFromGroup.valueChanges
      .subscribe({
        next: value => {
          this.getClients();
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getClients(seachValue?: string): void {
    this.clientService.clients(this.searchFromGroup.value)
      .pipe(take(1))
      .subscribe({
        next: clients => {
          this.results = clients.results
          this.loading = false
          this.dataSource.data = clients.results;
          this.dataSource.data = clients.results;
          this.paginator.length = clients.count;
          this.paginator.pageSize = 25;
          this.paginator.pageIndex = this.page - 1;

          this.table.renderRows();
        }
      });
  }

  openClientCreatePopup(client?: Client): void {
    this.dialog.open(ClientCreateComponent, {
      data: {
        client
      },
    })
      .afterClosed()
      .subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getClients();
          }
        }
      });
  }


  changePage(event: PageEvent): void {
    const newPageIndex = event.pageIndex + 1;
    this.router.navigate(["."], { relativeTo: this.route, queryParams: { page: newPageIndex } });
  }

  openClientPasswordUpdatePopup(client?: Client): void {
    this.dialog.open(ClientUpdatePasswordPopupComponent, {
      data: {
        client
      },
    })
      .afterClosed()
      .subscribe({
        next: _ => {

        }
      });
  }

  openClientDeletePopup(client: any): void {
    this.dialog.open(ClientDeletePopupComponent, {
      data: {
        client
      },
    })
      .afterClosed().subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getClients();
          }
        }
      });
  }

}
