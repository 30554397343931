<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav bg-backgound2" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false" autosize>
    <mat-toolbar class="w-100 text-white text-center">
      <div class="w-100">
        <a [routerLink]="['/client']">
<!--          <img src="../../../../../assets/logos/Tour_Review_Logo.png" />-->
        </a>
      </div>
    </mat-toolbar>
    <mat-nav-list>

      <!-- <ng-container *ngIf="user.role === UserRole['Client']">
        <a mat-list-item class="text-white" [routerLink]="['/dashboard']">
          <button mat-icon-button class="rounded" routerLinkActive="is-active-button">
            <svg routerLinkActive="is-active-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16">
              <path
                d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
              <path fill-rule="evenodd"
                d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
            </svg>
          </button>
          <span *ngIf="showFiller">Dashboard</span>
        </a>
      </ng-container> -->

      <ng-container *ngFor="let menu of menuList">
        <ng-container *ngIf="user.role === menu.role || menu.role === null  || menu.role === undefined">
          <a mat-list-item class="text-white" [routerLink]="[menu.url]">
            <button mat-icon-button class="rounded" routerLinkActive="is-active-button">
              <mat-icon routerLinkActive="is-active-icon">{{menu.icon}}</mat-icon>
            </button>
            <span></span>
          </a>
        </ng-container>
      </ng-container>

      <a mat-list-item class="text-white" (click)="logout()" [routerLink]="['/auth']">
        <button mat-icon-button>
          <mat-icon>logout</mat-icon>
        </button>
        <span></span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="bg-white">
    <div class="row">
      <div class="col-12 p-0">
        <app-toolbar-alert></app-toolbar-alert>
      </div>
    </div>
    <mat-toolbar class="bg-white d-flex container-fluid p-0 align-top">
      <div class="col-12">
        <div class="row d-flex justify-content-between">
          <div>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
          </div>
          <div class="pr-3 d-flex">
<!--            <div class="btn mr-2 d-flex align-items-center">-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"-->
<!--                class="bi bi-bell text-button" viewBox="0 0 16 16">-->
<!--                <path-->
<!--                  d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />-->
<!--              </svg>-->
<!--            </div>-->
            <div *ngIf="user" class="btn rounded-pill btn-grey custom-button d-flex align-items-center ">
              <div class="bg-button rounded-circle d-flex p-1 mr-2">
                <mat-icon class="text-white">face</mat-icon>
              </div>
              {{user.name}}
<!--              <mat-icon class="ml-3">expand_more</mat-icon>-->
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
