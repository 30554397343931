import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReviewService } from 'src/app/core/services/review.service';

@Component({
  selector: 'app-toolbar-alert',
  templateUrl: './toolbar-alert.component.html',
  styleUrls: ['./toolbar-alert.component.scss']
})
export class ToolbarAlertComponent implements OnInit {

  isVisible: boolean = false;

  constructor(
    private router: Router,
    private reviewService: ReviewService,
  ) { }

  ngOnInit(): void {
    window.setInterval(() => {
      if(this.router.url == '/dashboard'){
        this.reviewService.checkIfThereAreNewReviews().subscribe(data => {
          this.isVisible = data;
        });
      } else {
        this.isVisible = false
      }
    }, 5000);
  }

}
