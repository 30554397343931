import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {

  type: SnackbarType;
  title: string;
  message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      type: SnackbarType,
      title: string;
      message: string;
    }
  ) {
    this.title = data.title;
    this.type = data.type;
    this.message = data.message;
  }

  ngOnInit() {
  }

}

export const enum SnackbarType {
  "Error",
  "Success",
  "Warning"
}


