import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ExportEmailService } from 'src/app/core/services/export-email.service';
import { Email } from 'src/app/models/email';

@Component({
  selector: 'app-email-create-popup',
  templateUrl: './email-create-popup.component.html',
  styleUrls: ['./email-create-popup.component.scss']
})
export class EmailCreatePopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  createEmailForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl("", { validators: [Validators.required] }),
    email: new FormControl("", { validators: [Validators.required, Validators.email] })
  });


  constructor (
    public dialogRef: MatDialogRef<EmailCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email?: Email; },
    private emailService: ExportEmailService,
    private dialog: MatDialog
  ) {
    if (data.email) {
      this.createEmailForm.get("id")?.setValue(data.email.id);
      this.createEmailForm.get("name")?.setValue(data.email.name);
      this.createEmailForm.get("email")?.setValue(data.email.email);
    }
  }

  ngOnInit(): void {
  }

  createEmail(): void {

    this.createEmailForm.markAllAsTouched();
    if (this.createEmailForm.invalid) return;

    this.loading = true;

    this.emailService.createExportEmail(this.createEmailForm.value)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: email => {
          this.dialogRef.close(email);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
