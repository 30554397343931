import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-user-delete-popup',
  templateUrl: './user-delete-popup.component.html',
  styleUrls: ['./user-delete-popup.component.scss']
})
export class UserDeletePopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  constructor (
    public dialogRef: MatDialogRef<UserDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; },
    private userService: UserService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
  }

  deleteUser(): void {

    this.loading = true;

    this.userService.deleteUser(this.data.user.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
