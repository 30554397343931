<div class="body">
  <div>
    <h4 [ngClass]="{
      'warning': type === 2,
      'success': type === 1,
      'error': type === 0
    }">{{title}}</h4>
  </div>
  <div class="message">
    {{message}}
  </div>
</div>