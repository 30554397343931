import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { TourService } from 'src/app/core/services/tour.service';
import { Tour } from 'src/app/models/tour';

@Component({
  selector: 'app-tour-delete-popup',
  templateUrl: './tour-delete-popup.component.html',
  styleUrls: ['./tour-delete-popup.component.scss']
})
export class TourDeletePopupComponent implements OnInit {

 
  loading = false;
  responded = false;
  errorDuringResponse = false;

  constructor (
    public dialogRef: MatDialogRef<TourDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tour: Tour; },
    private tourService: TourService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
  }

  deleteTour(): void {

    this.loading = true;

    this.tourService.deleteTour(this.data.tour.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
