import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Review } from "../../../core/models/review.model";
import { ReviewService } from "../../../core/services/review.service";
import { ReviewPopupComponent } from "../review-popup/review-popup.component";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { ReportService } from "../../../core/services/report.service";
import { LocationService } from 'src/app/core/services/location.service';

const API_REVIEW: string = environment.baseLink + '/v1/api/reviews/';

@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.scss']
})
export class GeneralReportComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  reviews: Review[];
  displayedColumns: string[] = ["date", "source_stream_table", "Agency", "rating", "places"];
  dataSource = new MatTableDataSource();
  nextLink: string = '';
  showFilter: boolean = false;
  public isRotating = false;
  agencies: string[] = ["viator", "airbnb", "tripadvisor", "expedia", "klook", "civitatis", "getyourguide", "facebook", "google", "instagram", "linkedin"];
  filterForm: FormGroup;
  countries: [];
  tours: [];
  exportMenuOpen = false;
  selectedDateFromFilter: Date;
  selectedDateToFilter: Date;
  defaultSort = 'asc';

  start_date: string;
  end_date: string;
  searchFactor: string = 'source_stream';
  factors = [
    {
      "name": 'Streams',
      "factor": 'source_stream',
      "selected": true
    },
    {
      "name": 'Keywords',
      "factor": 'keywords',
    },
    {
      "name": 'Country',
      "factor": 'country_code'
    },
    {
      "name": 'Tours',
      "factor": 'tours'
    },
  ];

  exportType = 'pdf';
  exportTypes = [
    {
      "name": 'PDF',
      "value": 'pdf',
      "selected": true
    },
    {
      "name": 'Excel',
      "value": 'xlsx'
    }
  ];


  constructor (
    private reviewService: ReviewService,
    private reportService: ReportService,
    private locationService: LocationService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getReviews();
    this.locationService.getCountries().subscribe((data: any) => {
      this.countries = data;
    });
    this.reportService.getTours().subscribe((data: any) => {
      this.tours = data;
    });
    this.initFilterForm();
    let today = new Date();
    this.selectedDateToFilter = new Date();
    today.setMonth(today.getMonth() - 1);
    this.selectedDateFromFilter = today;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getReviews(next = false, refresh = false) {
    let url = API_REVIEW + '?page_size=25';
    if (next) {
      url = this.nextLink;
    }
    this.reviewService.get25Reviwes(url).subscribe(data => {
      // this.dataSource.data = data.results;
      this.nextLink = data.next ? data.next.replace('http://13.232.79.90', 'http://13.232.79.90:8000') : '';
      data.results.forEach((review: Review) => {
        if (!review.review_text) {
          review.review_text = '';
        }
        review.small_text = review.review_text.substring(0, 40) + '...';

        review.source_stream_table = review.source_stream.name;
        review.stars = Array(review.rating).fill('★');
        switch (review.sentiment) {
          case 3: {
            review.color = "success";
            review.sentiment_text = "Positive";
            break;
          }
          case 2: {
            review.color = "warning";
            review.sentiment_text = "Neutral";
            break;
          }
          case 1: {
            review.color = "danger";
            review.sentiment_text = "Negative";
            break;
          }
          default: {
            review.color = "warning";
            break;
          }
        }
        let places = [];
        for (let place of review.places) {
          if (place.type === 'location') {
            places.push(place);
          }
        }
        review.places = places;
      });
      if (refresh) {
        this.dataSource.data = data.results;

      } else {
        this.dataSource.data = this.dataSource.data.concat(data.results);
      }
    });
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      start_date: ['', Validators.compose([
      ])
      ],
      end_date: ['', Validators.compose([
      ])
      ],
      rating: ['', Validators.compose([
      ])
      ],
      agency: ['', Validators.compose([
      ])
      ],
      sentimentPositive: [false, Validators.compose([
      ])
      ],
      sentimentNegative: [false, Validators.compose([
      ])
      ],
      sentimentNeutral: [false, Validators.compose([
      ])
      ],
      responded: ['', Validators.compose([
      ])
      ],
      tour: ['', Validators.compose([
      ])
      ],
      country: ['', Validators.compose([
      ])
      ],
      sort_by: ['', Validators.compose([
      ])
      ],
    });
  }

  applyFilters(): void {
    const controls = this.filterForm.controls;
    let sentiment = [];
    let filter = {
      start_date: controls.start_date.value,
      end_date: controls.end_date.value,
      max_rating: controls.rating.value,
      min_rating: controls.rating.value,
      source_stream: controls.agency.value || "",
      country: controls.country.value,
      tour: controls.tour.value,
      sentiment: "",
      responded: controls.responded.value,
      sort_by: controls.sort_by.value
    };

    // format data for the be
    if (filter.start_date != "") {
      const offset = filter.start_date.getTimezoneOffset();
      filter.start_date = new Date(filter.start_date.getTime() - (offset * 60 * 1000));
      filter.start_date = filter.start_date.toISOString().split('T')[0];
    }

    if (filter.end_date != "") {
      const offset = filter.end_date.getTimezoneOffset();
      filter.end_date = new Date(filter.end_date.getTime() - (offset * 60 * 1000));
      filter.end_date = filter.end_date.toISOString().split('T')[0];
    }

    if (controls.sentimentNegative.value)
      sentiment.push("1");
    if (controls.sentimentNeutral.value)
      sentiment.push("2");
    if (controls.sentimentPositive.value)
      sentiment.push("3");

    filter.sentiment = sentiment.join(",");

    console.log(filter);
    this.reviewService.getReviewsWithFilter(filter).subscribe(data => {
      this.reviews = data.results;
      this.dataSource.data = data.results;
      this.nextLink = data.next ? data.next.replace('http://13.232.79.90', 'http://13.232.79.90:8000') : '';
      this.dataSource.data.forEach((review: Review) => {
        if (!review.review_text) {
          review.review_text = '';
        }
        review.small_text = review.review_text.substring(0, 40) + '...';
        review.source_stream_table = review.source_stream.name;
        review.stars = Array(review.rating).fill('★');
        switch (review.sentiment) {
          case 3: {
            review.color = "success";
            review.sentiment_text = "Positive";
            break;
          }
          case 2: {
            review.color = "warning";
            review.sentiment_text = "Neutral";
            break;
          }
          case 1: {
            review.color = "danger";
            review.sentiment_text = "Negative";
            break;
          }
          default: {
            review.color = "warning";
            break;
          }
        }
      });
    });
  }

  openReviewPopup(review: any) {
    this.dialog.open(ReviewPopupComponent, {
      data: { review },
      width: '700px',
    });
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  async export() {
    await this.reportService.downloadPDF(this.exportType,
      this.searchFactor,
      this.selectedDateFromFilter.toISOString().split('T')[0],
      this.selectedDateToFilter.toISOString().split('T')[0]
    );
  }

  resetFilters(): void {
    this.initFilterForm();
    this.getReviews();
  }

  dateFromChange($event) {
    this.selectedDateFromFilter = $event.value;
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter
    });
  }

  dateToChange($event) {
    this.selectedDateToFilter = $event.value;
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter
    });
  }


}
