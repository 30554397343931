<div>
  <p class="text-primary title">
    {{data.tour? "Edit Tour" : "Create Tour"}}
  </p>

  <form [formGroup]="createTourForm">

    <div class="row">

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" />
<!--          <mat-error *ngIf="createTourForm.get('email').invalid && createTourForm.get('email').errors['required']">This-->
<!--            field is rquired</mat-error>-->
<!--          <mat-error *ngIf="createTourForm.get('email').invalid && createTourForm.get('email').errors['required']">Please Enter a valid Email</mat-error>-->
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>Country</mat-label>
          <input matInput type="text" formControlName="country" />
          <mat-error
            *ngIf="createTourForm.get('country').invalid && createTourForm.get('country').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput type="text" formControlName="city" />
          <mat-error *ngIf="createTourForm.get('city').invalid && createTourForm.get('city').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

    </div>

    <div class="row">

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>Website</mat-label>
          <input matInput type="text" formControlName="website" />
          <mat-error
            *ngIf="createTourForm.get('website').invalid && createTourForm.get('website').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field class="w-100">
          <mat-label>Tour Operator</mat-label>
          <mat-select formControlName="tour_operator">
            <ng-container *ngFor="let tourOperator of tourOperatorList">
              <mat-option [value]="tourOperator.id">
                {{tourOperator.name}}
              </mat-option>
            </ng-container>
            <ng-container>
              <mat-option (click)="AddNewOparateropenDialog()">Add New
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="createTourForm.get('tour_operator').invalid && createTourForm.get('tour_operator').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field >
          <mat-label>Category</mat-label>
          <mat-select formControlName="category">
            <ng-container *ngFor="let item of categoryData">
              <mat-option [value]="item.name">
                {{item.name}}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error
            *ngIf="createTourForm.get('category').invalid && createTourForm.get('category').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>


    </div>

    <div class="row">
      <div class="col tourUrl-div">

        <div>
          <ng-container formArrayName="tour_urls">
            <table mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="url" class="mt-2">
                <th mat-header-cell *matHeaderCellDef width="60%"> URL </th>
                <td mat-cell *matCellDef="let element; let index=index;" [formGroupName]="index">
                  <mat-form-field class="w-100 pr-3">
                    <mat-label>URL</mat-label>
                    <input matInput type="text" formControlName="url" />
                    <mat-error
                      *ngIf="createTourForm.get('tour_urls').controls[index].get('url').invalid && createTourForm.get('tour_urls').controls[index].get('url').errors['required']">This
                      field is rquired</mat-error>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="stream">
                <th mat-header-cell *matHeaderCellDef> Stream </th>
                <td mat-cell *matCellDef="let element; let index=index;" [formGroupName]="index">
                  <mat-form-field>
                    <mat-label>Stream</mat-label>
                    <mat-select formControlName="stream">
                      <ng-container *ngFor="let urlStream of TourUrlStream | enumToList">
                        <mat-option [value]="urlStream.id">
                          {{urlStream.value}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="createTourForm.get('tour_urls').controls[index].get('stream').invalid && createTourForm.get('tour_urls').controls[index].get('stream').errors['required']">This
                      field is rquired</mat-error>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element; let index=index;" [formGroupName]="index">
                  <div class="btn custom-btn bg-danger text-white m-1" (click)="removeTourUrl(index)">
                    Delete
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </ng-container>
        </div>

      </div>
    </div>

    <div class="addTourUrl-div">
      <div class="btn custom-btn bg-primary text-white m-1" (click)="addTourUrl()">
        Add
      </div>
    </div>

  </form>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="createTour()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn bg-primary text-white mt-2 mr-1">
      {{data.tour? "Update" : "Create"}}
    </button>
    <button (click)="closePopup()" class="btn btn-outline-primary custom-btn-outline mt-2 mr-1">
      Close
    </button>
  </div>

</div>
