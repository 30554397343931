import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";



/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(
    private authServive: AuthService
  ) { }

  urlsToNotUse= [
    environment.baseLink + '/v1/auth/login/'
  ];

  // intercept request and add token
  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    console.log(request.url)
    if (this.isValidRequestForInterceptor(request.url)) {
      request = this.addToken(request, localStorage.getItem('access'))
    }

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // console.log(event.status);
          }
        },
        error => {
          if (error.status === 401) {
            return this.handle401Error(request, next)
          } else {
            console.error(error);
            console.error(error.statusText);
          }
        }
      )
    );

  }

  addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    return !this.urlsToNotUse.includes(requestUrl)
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.authServive.logout();
  }
}
