import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from 'src/app/models/client';
import { ClientCreate } from 'src/app/models/client-create';
import { ClientPasswordUpdate } from 'src/app/models/client-password-update';
import { PaginationResponse } from 'src/app/models/pagination-response';
import { environment } from 'src/environments/environment';


const API_CLIENTS: string = environment.baseLink + '/v1/api/clients/';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor (private http: HttpClient) { }

  clients(filters?: {}): Observable<PaginationResponse<Client>> {

    let params = new HttpParams();
    Object.keys(filters).forEach(key => {
      if (filters[key] !== null && filters[key] !== undefined && filters[key] !== "") {
        params = params.set(key, filters[key]);
      }
    });

    return this.http.get<PaginationResponse<Client>>(API_CLIENTS, { params });
  }

  createClient(client: ClientCreate): Observable<Client> {
    return this.http.post<Client>(API_CLIENTS, client);
  }

  updateClient(client: Client): Observable<Client> {
    return this.http.put<Client>(`${API_CLIENTS}${client.id}/`, client);
  }

  updateClientPassword(clientId: number, clientPassword: ClientPasswordUpdate): Observable<any> {
    return this.http.post<any>(`${API_CLIENTS}${clientId}/reset-password/`, clientPassword);
  }

  deleteClient(id: number): Observable<any> {
    return this.http.delete<any>(`${API_CLIENTS}${id}/`);
  }

}
