import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ExportScheduleService } from 'src/app/core/services/export-schedule.service';

@Component({
  selector: 'app-schedule-delete-popup',
  templateUrl: './schedule-delete-popup.component.html',
  styleUrls: ['./schedule-delete-popup.component.scss']
})
export class ScheduleDeletePopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  constructor (
    public dialogRef: MatDialogRef<ScheduleDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { schedule: any; },
    private scheduleService: ExportScheduleService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
  }

  deleteSchedule(): void {

    this.loading = true;

    this.scheduleService.deleteSchedule(this.data.schedule.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
