<div class="container-padding">
  <div class="col">

    <div class="row d-flex justify-content-between">
      <div class="col-12">
        <div class="card bg-white rounded">
          <div class="card-body col-12">

            <div class="card-title row d-flex justify-content-between">
              <div class="col d-flex">
                <h2>Schedule</h2>
              </div>
              <div class="col d-flex justify-content-end">
                <div (click)="openScheduleCreatePopup()"
                  class="btn rounded-pill text-white bg-primary h-75 d-flex mr-1 pt-2 tb-2 pl-3 pr-3">
                  <mat-icon matTooltip="edit">add</mat-icon>
                  <p class="mt ml-2">Create Schedule</p>
                </div>
                <a [routerLink]="['/email']"
                   class="btn rounded-pill text-white bg-primary h-75 d-flex mr-1 pt-2 tb-2 pl-3 pr-3">
                  <p class="mt ml-2">Add emails</p>
                </a>
              </div>
            </div>

            <div class="row">

              <mat-table #table [dataSource]="dataSource" matSort #sort1="matSort" matSortActive="Date"
                matSortDirection="asc">

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Name </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.name}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="emails">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Emails </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{getEmailListById(element.email_addresses)}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="search_factor">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Search Factor
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      <ng-container [ngSwitch]="element.search_factor">
                        <ng-template [ngSwitchCase]="0">
                          Streams
                        </ng-template>
                        <ng-template [ngSwitchCase]="1">
                          Keywords
                        </ng-template>
                        <ng-template [ngSwitchCase]="2">
                          Countries
                        </ng-template>
                        <ng-template [ngSwitchCase]="3">
                          Tours
                        </ng-template>
                      </ng-container>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="format">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Format </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      <ng-container [ngSwitch]="element.format">
                        <ng-template [ngSwitchCase]="0">
                          PDF
                        </ng-template>
                        <ng-template [ngSwitchCase]="1">
                          xlsx
                        </ng-template>
                        <ng-template [ngSwitchCase]="2">
                          xls
                        </ng-template>
                      </ng-container>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="date_range_export">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Date Range Export
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      <ng-container [ngSwitch]="element.date_range_export">
                        <ng-template [ngSwitchCase]="0">
                          Daily
                        </ng-template>
                        <ng-template [ngSwitchCase]="1">
                          Weekly
                        </ng-template>
                        <ng-template [ngSwitchCase]="2">
                          Monthly
                        </ng-template>
                      </ng-container>
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="schedule">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Schedule </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{getSchedule(element.schedule)}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Actions
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">

                    <div class="btn custom-btn bg-primary text-white m-1">
                      <div (click)="openScheduleCreatePopup(element)" style="text-decoration: none; color: white;">
                        Edit
                      </div>
                    </div>

                    <div class="btn custom-btn bg-danger text-white m-1" (click)="openScheduleDeletePopup(element)">Delete</div>

                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

              </mat-table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
