import {Component, Inject, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import {isPlatformBrowser} from "@angular/common";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {ReportService} from "../../../core/services/report.service";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-comparative-graphics',
  templateUrl: './comparative-graphics.component.html',
  styleUrls: ['./comparative-graphics.component.scss']
})
export class ComparativeGraphicsComponent {
  private chart: am4charts.XYChart;

  agencies = [
    {
      "name": "Viator",
      "value": "viator"
    },
    {
      "name": "Airbnb",
      "value": "airbnb"
    },
    {
      "name": "Tripadvisor",
      "value": "tripadvisor"
    },
    {
      "name": "Expedia",
      "value": "expedia"
    },
    {
      "name": "Klook",
      "value": "klook"
    },
    {
      "name": "Civitatis",
      "value": "civitatis"
    },
    {
      "name": "Get Your Guide",
      "value": "getyourguide"
    },
    {
      "name": "Facebook",
      "value": "facebook"
    },
    {
      "name": "Google",
      "value": "google"
    },
    {
      "name": "Instagram",
      "value": "instagram"
    },
    {
      "name": "Linkedin",
      "value": "linkedin"
    }
  ]
  selectedDateFromFilter: Date;
  selectedDateToFilter: Date;
  selectedAgenciesFilter: string = 'all';
  selectDateForm: FormGroup;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private reportService: ReportService, fb: FormBuilder) {
    this.reportService.filterDateChangeEvent.subscribe(event => {
      this.selectedDateFromFilter = event.dateFrom
      this.selectedDateToFilter = event.dateTo
      this.selectDateForm.controls['dateSelect'].setValue(event.selectValue);
      this.getFilterData();
    })

    this.reportService.agencyChangeEvent.subscribe(event => {
      this.selectedAgenciesFilter = event.agency;
      this.getFilterData();
    })

    this.selectDateForm = fb.group({
      'dateSelect': ['1'],
    });
  }


  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {

    let today = new Date();
    this.selectedDateToFilter = new Date();
    today.setMonth(today.getMonth() - 1);
    this.selectedDateFromFilter = today;

    // Chart code goes in here
    this.reportService.comparativeGraphics('all').subscribe((data: any) => {
      this.comparativeChart(data)
    });
  }

  comparativeChart(data) {
      am4core.useTheme(am4themes_animated);

      // Create chart instance
      this.chart = am4core.create("chartdivcomparativegreaphics", am4charts.XYChart);

    this.chart.data = data
// Create axes
    // Create axes
    let xAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "tour";
    xAxis.renderer.grid.template.location = 0;
    xAxis.renderer.labels.template.fontSize = 10;
    xAxis.renderer.minGridDistance = 30;
    xAxis.renderer.labels.template.disabled = true; // Disable default labels


    this.chart.scrollbarX = new am4core.Scrollbar();

    // if (this.chart.data.length > 20) { // change this condition based on your preference
    //   // xAxis.start = 0;
    //   // xAxis.end = 20 / this.chart.data.length;
    //   this.chart.scrollbarX.start = 0;
    //   this.chart.scrollbarX.end = 20 / this.chart.data.length;
    // }

    this.chart.scrollbarX.start = 0;
    this.chart.scrollbarX.end = 10 / this.chart.data.length;

    // Create new axis for agency names
    let agencyAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    if (this.selectedAgenciesFilter === 'all') {
      agencyAxis.dataFields.category = "agency";
    } else {
      agencyAxis.dataFields.category = "tour";
      agencyAxis.renderer.labels.template.disabled = true;
    }
    agencyAxis.renderer.grid.template.location = 0;
    agencyAxis.renderer.labels.template.fontSize = 10;
    agencyAxis.renderer.minGridDistance = 30;
    agencyAxis.renderer.labels.template.location = 0.5;
    // agencyAxis.renderer.labels.template.rotation = -90;


    let yAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

// Create series for passive
    let series2 = this.chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "passive";
    series2.dataFields.categoryX = "tour";
    series2.name = "Sales2";
    // series2.columns.template.tooltipText = "Passive: [bold]{valueY}[/]";
    series2.stacked = true;

// Create series for negative
    let series3 = this.chart.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = "negative";
    series3.dataFields.categoryX = "tour";
    series3.name = "Sales3";
    // series3.columns.template.tooltipText = "Negative: [bold]{valueY}[/]";
    series3.stacked = true;

    // Create series
    let series = this.chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "positive";
    series.dataFields.categoryX = "tour";
    series.name = "Sales";
    // series.columns.template.tooltipText = "Positive: [bold]{valueY}[/]";
    series.stacked = true;

// Configure column appearance for each series
    [series, series2, series3].forEach((s, index) => {

      s.columns.template.strokeWidth = 0;
      s.columns.template.adapter.add("fill", function (fill, target) {
        if (index === 0) {
          // Green color for passive
          return am4core.color("#28a745");
        }
        if (index === 1) {
          // Green color for passive
          return am4core.color("#ffc107");
        } else if (index === 2) {
          // Red color for negative
          return am4core.color("#ff4e36");
        }
        return fill;
      });

      if (index === 0) { // Add this condition
        // Add label to the tour columns
        let label = s.columns.template.createChild(am4core.Label);
        label.text = "{tour}";
        label.fontSize = 10;
        label.fill = am4core.color("#000");
        label.valign = "top";
        label.zIndex = -10;
        label.dy = -20;
        label.horizontalCenter = "middle";
        label.align = "center";
        label.wrap = true;
        label.maxWidth = 100; // Adjust this value to set the maximum width of the label
        label.truncate = true;
      }

      // Add label to the tour columns
      // let label = s.columns.template.createChild(am4core.Label);
      // label.text = "{tour}";
      // label.fontSize = 10;
      // label.fill = am4core.color("#000");
      // label.valign = "top";
      // label.zIndex = -10;
      // label.dy = -15;
      // label.horizontalCenter = "middle"
      // label.align = "center"; // Add this line

      // Add value labels inside columns
      let valueLabel = s.columns.template.createChild(am4core.Label);
      valueLabel.text = "{valueY}";
      valueLabel.fontSize = 10;
      valueLabel.fill = am4core.color("#fff");
      valueLabel.valign = "middle";
      // valueLabel.dy = 10;
      valueLabel.zIndex = 10;
      valueLabel.horizontalCenter = "middle";
      valueLabel.align = "center"; // Add this line

      // Use an adapter to set the label text based on the valueY
      valueLabel.adapter.add("text", function (text, target) {
        // @ts-ignore
        let valueY = target.dataItem && target.dataItem.valueY;
        return valueY > 0 ? valueY : "";
      });
    });



    let axisBreaks = {};
    let legendData = [];

// Add ranges
    function addRange(label, start, end, color) {
      let range = yAxis.axisRanges.create();
      // @ts-ignore
      range.category = start;
      // @ts-ignore
      range.endCategory = end;
      range.label.text = label;
      range.label.disabled = false;
      range.label.fill = color;
      range.label.location = 0;
      range.label.dx = -130;
      range.label.dy = 12;
      range.label.fontWeight = "bold";
      range.label.fontSize = 12;
      range.label.horizontalCenter = "left"
      range.label.inside = true;

      range.grid.stroke = am4core.color("#396478");
      range.grid.strokeOpacity = 1;
      range.tick.length = 200;
      range.tick.disabled = false;
      range.tick.strokeOpacity = 0.6;
      range.tick.stroke = am4core.color("#396478");
      range.tick.location = 0;

      range.locations.category = 1;
      let axisBreak = yAxis.axisBreaks.create();
      // @ts-ignore
      axisBreak.startCategory = start;
      // @ts-ignore
      axisBreak.endCategory = end;
      axisBreak.breakSize = 1;
      axisBreak.fillShape.disabled = true;
      axisBreak.startLine.disabled = true;
      axisBreak.endLine.disabled = true;
      axisBreaks[label] = axisBreak;

      legendData.push({name:label, fill:color});
    }

    addRange("Agency A", "Tour 1", "Tour 3", this.chart.colors.getIndex(0));
    addRange("Agency B", "Tour 4", "Tour 6", this.chart.colors.getIndex(1));
    addRange("Agency C", "Tour 7", "Tour 9", this.chart.colors.getIndex(2));

    this.chart.cursor = new am4charts.XYCursor();
  }

  selectChange($event){
    if($event.value == "")
      return;

    let today = new Date();

    if ( $event.value == 7 ){
      today.setDate(today.getDate() - 7 );
    } else {
      today.setMonth(today.getMonth() - $event.value);
    }

    $event.value = today;
    this.dateFromChange($event, false);
  }

  dateFromChange($event, resetSelect){
    this.selectedDateFromFilter = $event.value;

    if(resetSelect){
      this.selectDateForm.controls['dateSelect'].setValue('');
    }

    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter,
      selectValue: this.selectDateForm.controls['dateSelect'].value
    })
    this.getFilterData();
  }

  dateToChange($event){
    this.selectedDateToFilter = $event.value;
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter
    })
    this.getFilterData();
  }

  setDateFilter(value) : void {
    let today = new Date();
    let dateFrom

    if ( value == 7 ){
      dateFrom = today.setDate(today.getDate() - 7 );
    } else {
      dateFrom = today.setMonth(today.getMonth() - value);
    }

    this.selectedDateFromFilter = today
    this.selectedDateToFilter = new Date()
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: dateFrom,
      dateTo: today
    })
    this.getFilterData();
  }

  setAgencyFilter(value): void {
    this.selectedAgenciesFilter = value;
    this.reportService.agencyChangeEvent.emit({
      agency: this.selectedAgenciesFilter
    })
    this.getFilterData();
  }

  getFilterData(): void{
    this.reportService.comparativeGraphics(this.selectedAgenciesFilter, this.selectedDateFromFilter.toISOString().split('T')[0], this.selectedDateToFilter.toISOString().split('T')[0]).subscribe((data: any) => {
      this.comparativeChart(data)
    });
  }


  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
