import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ClientService } from 'src/app/core/services/client.service';
import { UserRole } from 'src/app/enums/user-role';
import { UserType } from 'src/app/enums/user-type';
import { Client } from 'src/app/models/client';
import {SnackbarType} from "../snack-bar/snack-bar.component";
import {SnackBarService} from "../../../core/services/snack-bar.service";

@Component({
  selector: 'app-client-create-popup',
  templateUrl: './client-create-popup.component.html',
  styleUrls: ['./client-create-popup.component.scss']
})
export class ClientCreateComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  UserRole = UserRole;
  UserType = UserType;

  createClientForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl("", { validators: [Validators.required] }),
    email: new FormControl("", { validators: [Validators.required, Validators.email] }),
    password: new FormControl("", { validators: [Validators.required] })
    // role: new FormControl("", { validators: [Validators.required] }),
    // user_type: new FormControl("", { validators: [Validators.required] }),
  });


  constructor(
    public dialogRef: MatDialogRef<ClientCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { client?: Client; },
    private clientService: ClientService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {
    if (data.client) {
      this.createClientForm.get("id")?.setValue(data.client.id);
      this.createClientForm.get("name")?.setValue(data.client.name);
      this.createClientForm.get("email")?.setValue(data.client.email);
      this.createClientForm.get("role")?.setValue(3);
      this.createClientForm.get("user_type")?.setValue(data.client.user_type);

      this.createClientForm.get("password")?.disable();
    }
  }

  ngOnInit(): void {
  }

  createClient(): void {

    this.createClientForm.markAllAsTouched();
    if (this.createClientForm.invalid) return;

    this.loading = true;

    if (this.data.client) {
      this.clientService.updateClient(this.createClientForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: client => {
            this.dialogRef.close(client);
            this.snackBarService.openSnackBar("User Updated", "User Updated Successully", SnackbarType.Success);
          }
        });
    }
    else {
      this.clientService.createClient(this.createClientForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: client => {
            this.dialogRef.close(client);
            this.snackBarService.openSnackBar("User Created", "User Created Successully", SnackbarType.Success);
          }
        });
    }


  }

  closePopup() {
    this.dialog.closeAll();
  }

}
