import {Component, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {ReportService} from "../../../core/services/report.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-location-of-reviews',
  templateUrl: './location-of-reviews.component.html',
  styleUrls: ['./location-of-reviews.component.scss']
})
export class LocationOfReviewsComponent {
  private chart;
  @ViewChild(MatSort) sort: MatSort;

  agencies = [
    {
      "name": "Viator",
      "value": "viator"
    },
    {
      "name": "Airbnb",
      "value": "airbnb"
    },
    {
      "name": "Tripadvisor",
      "value": "tripadvisor"
    },
    {
      "name": "Expedia",
      "value": "expedia"
    },
    {
      "name": "Klook",
      "value": "klook"
    },
    {
      "name": "Civitatis",
      "value": "civitatis"
    },
    {
      "name": "Get Your Guide",
      "value": "getyourguide"
    },
    {
      "name": "Facebook",
      "value": "facebook"
    },
    {
      "name": "Google",
      "value": "google"
    },
    {
      "name": "Instagram",
      "value": "instagram"
    },
    {
      "name": "Linkedin",
      "value": "linkedin"
    }
  ]
  selectedDateFromFilter: Date;
  selectedDateToFilter: Date;
  selectedAgenciesFilter: string = 'all';
  displayedColumns: string[] = ["country", "positive", "passive", 'negative', "sentiment_percentage"]
  dataSource = new MatTableDataSource();
  selectDateForm: FormGroup;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private reportService: ReportService, private fb: FormBuilder) {
    this.reportService.filterDateChangeEvent.subscribe(event => {
      this.selectedDateFromFilter = event.dateFrom
      this.selectedDateToFilter = event.dateTo
      this.selectDateForm.controls['dateSelect'].setValue(event.selectValue);
      this.getFilterData();
    })

    this.reportService.agencyChangeEvent.subscribe(event => {
      this.selectedAgenciesFilter = event.agency;
      this.getFilterData();
    })

    this.selectDateForm = fb.group({
      'dateSelect': ['1'],
    });
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {

    let today = new Date();
    this.selectedDateToFilter = new Date();
    today.setMonth(today.getMonth() - 1);
    this.selectedDateFromFilter = today;

    // Chart code goes in here
    this.reportService.reviewsLocation('all').subscribe((data: any) => {
      this.locationChart(data)
      this.dataSource.data = data.slice(0, 3)
    });
  }

  locationChart(data) {
    console.log(data)
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create map instance
    let chart = am4core.create("chartdivlocationreviews", am4maps.MapChart);
    chart.maxZoomLevel = 50;
    chart.homeZoomLevel = 1.5;

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;
    polygonSeries.exclude = ["AQ"]; // exclude Antarctica from the map

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = chart.colors.getIndex(0);

    // Create a series
    let series = chart.series.push(new am4maps.MapImageSeries());
    series.dataFields.value = "value";

    let template = series.mapImages.template;
    template.nonScaling = false;
    series.data = data



  // Add map object bullets after the chart is ready
    chart.events.on("ready", () => {
      series.data.forEach(function (item) {
        let polygon = polygonSeries.getPolygonById(item.id);
        if (polygon) {
          let centerPoint = {
            longitude: polygon.visualLongitude,
            latitude: polygon.visualLatitude,
          };

          let bullet = series.mapImages.create();
          bullet.latitude = centerPoint.latitude;
          bullet.longitude = centerPoint.longitude;

          let circle = bullet.createChild(am4core.Circle);
          circle.fillOpacity = 0.7;
          circle.verticalCenter = "middle";
          circle.horizontalCenter = "middle";
          circle.nonScaling = false;

          circle.fill = am4core.color(item.color);
          circle.radius = 10;

          bullet.tooltipText = `${item.name}\n\n` +
            `Positive: ${item.positive}\n`+
            `Passive: ${item.passive}\n`+
            `Negative: ${item.negative}\n`;

          let label = bullet.createChild(am4core.Label);
          label.text = item.value;
          label.fill = am4core.color("#fff");
          label.verticalCenter = "middle";
          label.horizontalCenter = "middle";
          label.nonScaling = false;
          label.fontSize = 10;

          // Set dataItem after creating the bullet and its children
          setTimeout(() => {
            bullet.dataItem = item;
          }, 0);
        }
      });
    });
  }

  selectChange($event){
    if($event.value == "")
      return;

    let today = new Date();

    if ( $event.value == 7 ){
      today.setDate(today.getDate() - 7 );
    } else {
      today.setMonth(today.getMonth() - $event.value);
    }

    $event.value = today;
    this.dateFromChange($event, false);
  }

  dateFromChange($event, resetSelect){
    this.selectedDateFromFilter = $event.value;

    if(resetSelect){
      this.selectDateForm.controls['dateSelect'].setValue('');
    }

    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter,
      selectValue: this.selectDateForm.controls['dateSelect'].value
    })
    this.getFilterData();
  }

  dateToChange($event){
    this.selectedDateToFilter = $event.value;
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter
    })
    this.getFilterData();
  }

  setAgencyFilter(value): void {
    this.selectedAgenciesFilter = value;
    this.reportService.agencyChangeEvent.emit({
      agency: this.selectedAgenciesFilter
    })
    this.getFilterData();
  }

  getFilterData(): void{
    this.reportService.reviewsLocation(this.selectedAgenciesFilter, this.selectedDateFromFilter.toISOString().split('T')[0], this.selectedDateToFilter.toISOString().split('T')[0]).subscribe((data: any) => {
      this.locationChart(data)
      this.dataSource.data = data.slice(0, 3)
    });
  }

}
