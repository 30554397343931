<div>
  <p class="text-primary title">
    {{data.email? "Edit Email" : "Create Email"}}
  </p>

  <form [formGroup]="createEmailForm">
    <div class="">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error *ngIf="createEmailForm.get('name').invalid && createEmailForm.get('name').errors['required']">This
          field is rquired</mat-error>
      </mat-form-field>
    </div>

    <div class="">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email" />
        <mat-error *ngIf="createEmailForm.get('email').invalid && createEmailForm.get('email').errors['required']">This
          field is rquired</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="createEmail()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn bg-primary text-white mt-2 mr-1">
      {{data.email? "Update" : "Create"}}
    </button>
    <button (click)="closePopup()" class="btn btn-outline-primary custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>