<div class="container-padding">
  <div class="col">

    <div class="row d-flex justify-content-between">
      <div class="col-12">
        <div class="card bg-white rounded">
          <div class="card-body col-12">

            <div class="card-title row d-flex justify-content-between">
              <div class="col d-flex">
                <h2>Users</h2>
              </div>
              <div class="col d-flex justify-content-end">
                <div (click)="openUserCreatePopup()"
                  class="btn rounded-pill text-white bg-primary h-75 d-flex mr-1 pt-2 tb-2 pl-3 pr-3">
                  <mat-icon matTooltip="edit">add</mat-icon>
                  <p class="mt  ml-2">Create User</p>
                </div>
              </div>
            </div>

            <div class="row">

              <mat-table #table [dataSource]="dataSource" matSort #sort1="matSort" matSortActive="Date"
                matSortDirection="asc">

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Name </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.name}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Email </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.email}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="userType">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> User Type
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{UserType[element.user_type]}}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Actions
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <div class="btn custom-btn bg-primary text-white m-1" (click)="openUserCreatePopup(element)">
                      Edit
                    </div>
                    <div class="btn custom-btn bg-danger text-white m-1" (click)="openUserDeletePopup(element)">
                      Delete
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

              </mat-table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>