import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/core/services/user.service';
import { UserType } from 'src/app/enums/user-type';
import { User } from 'src/app/models/user';
import { UserCreatePopupComponent } from '../user-create-popup/user-create-popup.component';
import { UserDeletePopupComponent } from '../user-delete-popup/user-delete-popup.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {

  UserType = UserType;

  displayedColumns: string[] = ["name", "email", "userType", "actions"];

  dataSource = new MatTableDataSource<User>();
  @ViewChild(MatSort) sort: MatSort;


  constructor (
    private userService: UserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getUsers(): void {
    this.userService.getUsers().subscribe({
      next: users => {
        this.dataSource.data = users.results;
      }
    });
  }

  openUserCreatePopup(user?: User): void {
    this.dialog.open(UserCreatePopupComponent, {
      data: {
        user
      },
    })
      .afterClosed()
      .subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getUsers();
          }
        }
      });
  }

  openUserDeletePopup(user: User): void {
    this.dialog.open(UserDeletePopupComponent, {
      data: {
        user
      },
    })
      .afterClosed().subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getUsers();
          }
        }
      });
  }

}
