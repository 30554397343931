<div class="container-padding">
  <div class="col">

    <div class="row d-flex justify-content-between">
      <div class="col-12">
        <div class="card bg-white rounded">
          <div class="card-body">
            <div class="card-title row d-flex justify-content-between">
              <div class="col d-flex">
                <h2>Reviews Location</h2>
              </div>
              <div class="col d-flex justify-content-end">
                <div [formGroup]="selectDateForm" class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-label>Select a prediod</mat-label>
                    <mat-select autocomplete="off" value="" (selectionChange)="selectChange($event)" formControlName="dateSelect">
                      <mat-option value="">
                      </mat-option>
                      <mat-option value="12">
                        Last Year
                      </mat-option>
                      <mat-option value="1">
                        Last Month
                      </mat-option>
                      <mat-option value="7">
                        Last Week
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-label>Date From</mat-label>
                    <input matInput (focus)="picker1.open()" [value]="selectedDateFromFilter" (dateChange)="dateFromChange($event, true)" [matDatepicker]="picker1" [readonly]="true" autocomplete="off">
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-label>Date To</mat-label>
                    <input matInput (focus)="picker2.open()" [value]="selectedDateToFilter" (dateChange)="dateToChange($event)" [matDatepicker]="picker2" [readonly]="true" autocomplete="off">
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-select class="agency-field" autocomplete="off" [(value)]="selectedAgenciesFilter" (selectionChange)="setAgencyFilter($event.value)">
                      <mat-option [value]="'all'" selected>
                        <img class="img-fluid mr-2" style="max-width:20px;" [src]="'../../../../assets/logos/all.png'">
                        Everything
                      </mat-option>
                      <mat-option *ngFor="let agency of agencies" [value]="agency.value">
                        <ng-container matOption>
                          <img class="img-fluid mr-2" style="max-width:20px;" src="../../../../assets/logos/{{agency.value}}.png">
                          {{agency.name}}
                        </ng-container>
                      </mat-option>
                    </mat-select>
                    <div class="selected-icon">
                      <img class="img-fluid" style="max-width:20px;" [src]="'../../../../assets/logos/' + selectedAgenciesFilter + '.png'" >
                    </div>
                  </mat-form-field>
                </div>
            </div>

            <div id="chartdivlocationreviews" style="width: 100%; height: 400px"></div>
            <div class="row col-12">
              <mat-table #table [dataSource]="dataSource" style="width: 100%" matSort #sort1="matSort" matSortActive="stream" matSortDirection="asc">
                <ng-container matColumnDef="country">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Country</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <img class="img-fluid mr-3" style="max-width:12%;"
                         src="../../../../assets/flags/{{element.name.toLowerCase()}}.svg">
                    {{element.name}}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="positive">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Positive Reviews </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{element.positive}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="passive">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Passive Reviews </mat-header-cell>
                  <mat-cell *matCellDef="let element" class=""> {{element.passive}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="negative">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Negative Reviews </mat-header-cell>
                  <mat-cell *matCellDef="let element" class=""> {{element.negative}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sentiment_percentage">
                  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary justify-content-between"> Score </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="container justify-content-between">
                    <div class="font-weight-bold">{{element.sentiment_percentage}}%</div>
                    <mat-progress-bar mode="determinate" value="{{element.sentiment_percentage}}" class="col-8"></mat-progress-bar>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
