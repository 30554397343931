<div class="container-padding bg-backgorund2">
    <div class="col-12">
        <div class="row d-flex justify-content-between">
            <div class="col-8">
                <div class="card bg-white rounded nps-boxes">
                    <div class="card-body">
                        <div class="row border-bottom dashed justify-content-around nps-padding">
                          <div class="col-4">
                            NPS (Net promotion score)
                          </div>
                          <div class="col-1">
                            =
                          </div>
                          <div class="col-3">
                            <div class="row">
                              <mat-icon class="text-success icon-display mb-2 mr-4">mood</mat-icon>
                              <p>
                                % Promotors
                              </p>
                            </div>
                          </div>
                          <div class="col-1">
                              -
                          </div>
                          <div class="col-3">
                            <div class="row">
                              <mat-icon class="text-danger icon-display mb-2 mr-4">mood_bad</mat-icon>
                              <p>
                                % Dettractors
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="w-100 d-flex justify-content-between container nps-padding" disabled>
                                <div class="col-4">
                                  <div class="row">
                                    <div class="d-flex container p-3">
                                      CSAT:
                                      <mat-icon class="text-warning pl-2">{{nps_starts}}</mat-icon>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-8">
                                  <div class="row">
                                    <div class="w-100 d-flex justify-content-around p-3 container">
                                      <div>
                                        NPS: {{statistics.nps.nps}}%
                                      </div>
                                      <div>
                                        Detractors: {{statistics.nps.detractors}}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card bg-white rounded nps-boxes ">
                    <img class="img-fluid rounded card-img-top" src="../../../../assets/logos/stock_image_analysis.png">
                    <div class="card-body d-flex justify-content-center">
                      <div [routerLink]="['/report']" class="btn rounded-pill text-white bg-primary custom-button">
                          Download Report
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
