<div style="max-height: 500px; overflow-y: auto;">
  <table class="mat-table" mat-table #table [dataSource]="dataSource" matSort #sort1="matSort" matSortActive="Date"
         matSortDirection="asc" multiTemplateDataRows>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Email </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper">
          {{ element.email && element.email.length > 20 ? (element.email | slice:0:20) + '...' :
          element.email }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="website">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Website </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper">
          {{ element.website && element.website.length > 20 ? (element.website | slice:0:20) + '...' :
          element.website }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Country </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper">
          {{element.country}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> State </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper">
          {{element.state}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> City </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper">
          {{element.city}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Rating </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper stars">
          <ng-container *ngFor="let i of [].constructor(Math.round(element.rating)); let index = index">
            ★
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="NReviews">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> N. Reviews </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper">
          {{element.n_reviews}}
        </div>
      </td>
    </ng-container>
<!--    <ng-container matColumnDef="expandedDetail">-->
<!--      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">-->
<!--        <div class="expanded-element-container"-->
<!--             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">-->

<!--          <div class="expanded-content">-->
<!--            <ul class="tour_urls max-width-80">-->
<!--              <ng-container *ngFor="let item of element.tour_urls">-->
<!--                <li>-->
<!--                  <b>{{ TourUrlStream[item.stream] }}</b>: <a [href]="item.url" target="_blank">{{ item.url-->
<!--                  }}</a>-->
<!--                </li>-->
<!--              </ng-container>-->
<!--            </ul>-->

<!--          </div>-->

<!--        </div>-->
<!--      </td>-->
<!--    </ng-container>-->

    <ng-container matColumnDef="dateCreated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Date Created
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="chip-wrapper">
          {{element.date_created | date}}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

<!--    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expnadable-tr"></tr>-->
  </table>

  <div>
    <mat-paginator (page)="changePage($event)" aria-label="Select page">
    </mat-paginator>
  </div>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="closePopup()" class="btn btn-outline-primary custom-btn-outline mt-2 mr-1">
      Close
    </button>
  </div>

</div>
