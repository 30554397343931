<h1 mat-dialog-title>Create New Operator </h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>New Operator</mat-label>
    <input matInput [formControl]="new_operator">
    <mat-error *ngIf="new_operator.hasError('required')">This field is required</mat-error>

  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="submit()">Ok</button>
</div>
