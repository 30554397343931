<div>
  <p class="text-primary title">
    {{data.client? "Edit Employee" : "Create Employee"}}
  </p>

  <form [formGroup]="createClientForm">

    <div class="row">

      <div class="col">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error
            *ngIf="createClientForm.get('name').invalid && createClientForm.get('name').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput type="text" formControlName="email" />
          <mat-error
            *ngIf="createClientForm.get('email').invalid && createClientForm.get('email').errors['required']">This
            field is rquired</mat-error>
            <mat-error
              *ngIf="createClientForm.get('email').invalid && createClientForm.get('email').errors['email']">Please Enter a valid Email</mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="!data.client">
        <div class="col-4">
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" />
            <mat-error
              *ngIf="createClientForm.get('password').invalid && createClientForm.get('password').errors['required']">This
              field is rquired</mat-error>
          </mat-form-field>
        </div>
      </ng-container>

    </div>

    <!--    <div class="row">-->

    <!--      <div class="col-4">-->
    <!--        <mat-form-field appearance="outline">-->
    <!--          <mat-label>User Role</mat-label>-->
    <!--          <mat-select formControlName="role">-->
    <!--            <ng-container *ngFor="let userRole of UserRole | enumToList">-->
    <!--              <mat-option [value]="userRole.id">{{userRole.value}}</mat-option>-->
    <!--            </ng-container>-->
    <!--          </mat-select>-->
    <!--        </mat-form-field>-->
    <!--      </div>-->

    <!--      <div class="col-4">-->
    <!--        <mat-form-field appearance="outline">-->
    <!--          <mat-label>User Type</mat-label>-->
    <!--          <mat-select formControlName="user_type">-->
    <!--            <ng-container *ngFor="let userType of UserType | enumToList">-->
    <!--              <mat-option [value]="userType.id">{{userType.value}}</mat-option>-->
    <!--            </ng-container>-->
    <!--          </mat-select>-->
    <!--        </mat-form-field>-->
    <!--      </div>-->

    <!--    </div>-->

  </form>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="createClient()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn bg-primary text-white mt-2 mr-1">
      {{data.client? "Update" : "Create"}}
    </button>
    <button (click)="closePopup()" class="btn btn-outline-primary custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>