import { Component, OnInit } from '@angular/core';
import { ReviewTotal } from 'src/app/core/models/review-total.model';
import { ReviewService } from 'src/app/core/services/review.service';

@Component({
  selector: 'app-nps-dowload-report',
  templateUrl: './nps-dowload-report.component.html',
  styleUrls: ['./nps-dowload-report.component.scss']
})
export class NpsDowloadReportComponent implements OnInit {

  statistics: ReviewTotal = {
    reviews: {
      this_month: 0,
      positive: 0,
      neutral: 0,
      negative: 0,
      overall: 0,
      responded: 0
    },
    last_sync: new Date(),
    alerts: [],
    nps: {
      csat: 0,
      nps: 0,
      detractors: 0
    }
  }

  nps_starts: any;

  constructor(
    private reviewService: ReviewService 
  ) { }

  ngOnInit(): void {
    this.reviewService.getReviewsTotals().subscribe(data => {
      this.statistics = data;
      this.nps_starts = Array(Math.floor(this.statistics.nps.csat)).fill('star');
      if( Math.floor(this.statistics.nps.csat) != this.statistics.nps.csat) {
        this.nps_starts.push('star_half')
      }
      this.nps_starts = this.nps_starts.join(' ');
    })
  }

}
