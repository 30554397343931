import { Component, OnInit } from '@angular/core';
import { Review } from 'src/app/core/models/review.model';
import { ReviewService } from 'src/app/core/services/review.service';

@Component({
  selector: 'app-last-reviwes',
  templateUrl: './last-reviwes.component.html',
  styleUrls: ['./last-reviwes.component.scss']
})
export class LastReviwesComponent implements OnInit {

  positiveReviews: Review[];
  negativeReviews: Review[];
  neutralReviews: Review[];

  constructor(
    private reviewService: ReviewService
  ) { }

  ngOnInit(): void {
    this.reviewService.getReviwesWithSentiment(3).subscribe(data => {
      this.positiveReviews = data;
      this.positiveReviews.forEach(review => {
        if (!review.review_text) {
          review.review_text = ''
        }
        review.small_text = review.review_text.substring(0,40) + '...';
        review.source_stream_table = review.source_stream.name;
        review.stars = Array(review.rating).fill('★');
      })
    });

    this.reviewService.getReviwesWithSentiment(1).subscribe(data => {
      this.negativeReviews = data;
      this.negativeReviews.forEach(review => {
        if (!review.review_text) {
          review.review_text = ''
        }
        review.small_text = review.review_text.substring(0,40) + '...';
        review.source_stream_table = review.source_stream.name;
        review.stars = Array(review.rating).fill('★');
      })
    });

    this.reviewService.getReviwesWithSentiment(2).subscribe(data => {
      this.neutralReviews = data;
      this.neutralReviews.forEach(review => {
        if (!review.review_text) {
          review.review_text = ''
        }
        review.small_text = review.review_text.substring(0,40) + '...';
        review.source_stream_table = review.source_stream.name;
        review.stars = Array(review.rating).fill('★');
      })
    });
  }

}
