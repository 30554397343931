import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { PaginationResponse } from 'src/app/models/pagination-response';
import { Rating } from 'src/app/models/rating';
import { TourOperator } from 'src/app/models/tour-operator';
import { TourOperatorCreate } from 'src/app/models/tour-operator-create';
import { environment } from 'src/environments/environment';

const API_TOUR_OPERATOR: string =
  environment.baseLink + '/v1/api/tour-operators';

const API_TOUR_OPERATOR_NO_PAGINATION: string =
  environment.baseLink + '/v1/api/tour-operators-no-pagination/';

const API_TOUR_OPERATOR_REVIEW: string = environment.baseLink + "/v1/api/tour_review_average/";

@Injectable({
  providedIn: 'root',
})
export class TourOperatorService {
  constructor (private http: HttpClient) { }

  getTourOperators(filters: any, pagination: Pagination): Observable<PaginationResponse<TourOperator>> {

    let params = new HttpParams();
    params = params.set('page', pagination.page.toString());
    for (const key in filters) {
      if (filters[key] !== null && filters[key] !== "") {
        params = params.set(key, filters[key]);
      }
    }

    return this.http.get<PaginationResponse<TourOperator>>(`${API_TOUR_OPERATOR}/`, { params });
  }

  getTourOperatorsNoPagination(): Observable<TourOperator[]> {
    return this.http.get<TourOperator[]>(`${API_TOUR_OPERATOR_NO_PAGINATION}`);
  }

  createTourOperator(tourOperator: TourOperatorCreate): Observable<TourOperator> {
    return this.http.post<TourOperator>(`${API_TOUR_OPERATOR}/`, tourOperator);
  }

  updateTourOperator(tourOperator: TourOperator): Observable<TourOperator> {
    return this.http.put<TourOperator>(`${API_TOUR_OPERATOR}/${tourOperator.id}/`, tourOperator);
  }

  deleteTourOperator(id: number): Observable<any> {
    return this.http.delete(`${API_TOUR_OPERATOR}/${id}/`);
  }

  tourReviewAverage(tourOperatorId: number, startDate: string, endDate: string, location?: {
    country: string,
    state?: string,
    city?: string;
  }): Observable<Rating> {
    let params = new HttpParams();
    params = params.set("tour_operator_id", tourOperatorId.toString());
    params = params.set("start_date", startDate);
    params = params.set("end_date", endDate);

    if (location) {
      params = params.set("country", location.country);
      if (location.state) {
        params = params.set("state", location.state);
      }
      if (location.city) {
        params = params.set("city", location.city);
      }
    }

    return this.http.get<Rating>(`${API_TOUR_OPERATOR_REVIEW}`, { params });
  }

}
