<div class="container-padding">
  <div class="col">
    <div *ngIf="showFilter" class="row mb-4">
      <div class="card bg-white rounded filters">
        <div class="card-body">
          <div class="card-title d-flex justify-content-between ml-1">
            <h4>Filter Review</h4>
            <div class="btn">
              <mat-icon matTooltip="close" class="text-muted" (click)="toggleFilter()">close</mat-icon>
            </div>
          </div>
          <hr>
          <form class="form" [formGroup]="filterForm" autocomplete="off" novalidate="novalidate">
            <div class="row mt-3 mr-1">
              <div class="form-group col-3">
                By Stars
                <mat-form-field appearance="outline" class="text-warning report-select">
                  <mat-select formControlName="rating"  autocomplete="off">
                    <mat-option class="text-warning" [value]="1">
                      ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="2">
                      ★ ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="3">
                      ★ ★ ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="4">
                      ★ ★ ★ ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="5">
                      ★ ★ ★ ★ ★
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group col-3">
                By Agency
                <mat-form-field appearance="outline">
                  <mat-select formControlName="agency"  autocomplete="off">
                    <mat-option *ngFor="let agency of agencies" [value]="agency">
                      {{agency}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-group col-3">
                Date From
                <mat-form-field appearance="outline">
                  <input matInput (focus)="picker1.open()" [matDatepicker]="picker1" [readonly]="true" formControlName="start_date"  autocomplete="off">
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-3">
                Date To
                <mat-form-field appearance="outline">
                  <input matInput (focus)="picker2.open()" [matDatepicker]="picker2" [readonly]="true" formControlName="end_date"  autocomplete="off">
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="form-group col-3">
                By Tour
                <mat-form-field appearance="outline">
                  <mat-select formControlName="tour"  autocomplete="off">
                    <mat-option *ngFor="let tour of tours" [value]="tour.id">
                      {{tour.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-group col-3">
                By Country
                <mat-form-field appearance="outline">
                  <mat-select formControlName="country"  autocomplete="off">
                    <mat-option *ngFor="let  of countries" [value]="country.id">
                      {{country.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-group col-3">
                Responded
                <mat-form-field appearance="outline">
                  <mat-select formControlName="responded"  autocomplete="off">
                    <mat-option value="">

                    </mat-option>
                    <mat-option value="true">
                      Yes
                    </mat-option>
                    <mat-option value="false">
                      No
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-group col-3">
                Sort By Date
                <mat-form-field appearance="outline">
                  <mat-select formControlName="sort_by" [(ngModel)]="defaultSort" autocomplete="off">
                    <mat-option value="asc">
                      Ascending
                    </mat-option>
                    <mat-option value="desc">
                      Descending
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>
            <div class="row justify-content-around mt-3" >
              <div class="form-group d-flex justify-content-sm-start col-5">
                <!--                <p>By sentiment: </p>-->
                <mat-label>By sentiment: </mat-label>

                <mat-checkbox formControlName="sentimentPositive">Promoters</mat-checkbox>
                <mat-checkbox formControlName="sentimentNegative">Detractors</mat-checkbox>
                <mat-checkbox formControlName="sentimentNeutral">Passive</mat-checkbox>
              </div>

              <div class="col">
                <div class="d-flex justify-content-end">
                  <div (click)="resetFilters()" class="btn btn-outline-primary rounded-pill mr-1">
                    Reset
                  </div>
                  <div (click)="applyFilters()" class="btn rounded-pill text-white bg-primary ml-1 mr-3">
                    Save
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-between ">
      <div class="card bg-white rounded" style="width: 100%">
        <div class="card-body">
          <div class="card-title d-flex justify-content-between">
            <div class="col d-flex">
              <h2>General Report</h2>
            </div>
            <div (click)="toggleFilter()" class="btn custom-btn bg-primary text-white m-1 d-flex align-items-center p-2 px-4">
              Filters
            </div>
            <div class="export-container position-relative">
              <div (click)="exportMenuOpen = !exportMenuOpen" class="btn custom-btn bg-primary text-white m-1 d-flex align-items-center p-2 px-4">
                Export
              </div>
              <div class="menu-overlay position-absolute" *ngIf="exportMenuOpen">
                <form #form="ngForm" class="export-menu">
                  <div class="d-flex">
                    <h4>Export</h4>
                  </div>
                  <div class="mt-5 section">
                    <div class="text-muted">Step 1</div>
                    <div class="font-weight-bold">Search Factor</div>
                    <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      (change)="searchFactor = $event.value">
                      <mat-radio-button class="example-radio-button" *ngFor="let factor of factors" [value]="factor.factor" [checked]="factor.selected">
                        {{factor.name}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div class="mt-3 section">
                    <div class="text-muted">Step 2</div>
                    <div class="font-weight-bold">Format</div>
                    <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      (change)="exportType = $event.value">
                      <mat-radio-button class="example-radio-button" *ngFor="let type of exportTypes" [value]="type.value" [checked]="type.selected">
                        {{type.name}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div class="mt-3 section">
                    <div class="text-muted">Step 3</div>
                    <div class="font-weight-bold">Date Range</div>
                    <div class="date-section">
                      <div class="form-group ml-1">
                        <mat-form-field appearance="outline">
                          <mat-label>Date From</mat-label>
                          <input matInput (focus)="picker1.open()" [value]="selectedDateFromFilter" (dateChange)="dateFromChange($event)" [matDatepicker]="picker1" [readonly]="true" autocomplete="off">
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="form-group ml-1">
                        <mat-form-field appearance="outline">
                          <mat-label>Date To</mat-label>
                          <input matInput (focus)="picker2.open()" [value]="selectedDateToFilter" (dateChange)="dateToChange($event)" [matDatepicker]="picker2" [readonly]="true" autocomplete="off">
                          <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-end mt-2">
                    <div (click)="export()" class="btn custom-btn bg-primary text-white m-1 d-flex align-items-center p-2 px-4">
                      Download
                    </div>
                  </div>


                </form>
              </div>
            </div>
            <a [routerLink]="['/schedule']" class="btn custom-btn bg-primary text-white m-1 d-flex align-items-center p-2 px-4">
              Schedule Export
            </a>
          </div>


          <!-- TEST TABLE 2 -->
          <div class="row">
            <mat-table #table [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="Date"
                       matSortDirection="asc"
                       class="">

              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Date Posted </mat-header-cell>
                <mat-cell *matCellDef="let element" class="border-left-x2 border-{{element.color}}"> {{element.date | date:'dd-MM-yyyy'}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="source_stream_table">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Source</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a href="{{element.review_url}}" target="_blank">
                    <img class="img-fluid-report mr-1" style="max-width:15%;" src="../../../../assets/logos/{{element.source_stream_display}}.png" >
                    {{element.source_stream_table}}
                  </a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Agency">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Tour </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.product ? element.product : element.agency}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="rating">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" > Rating </mat-header-cell>
                <mat-cell *matCellDef="let element" class="text-{{element.color}}">
<!--                  {{element.stars.join('')}}-->
                  <p *ngFor="let star of element.stars" class="stars">{{star}}</p>

                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="places">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" > Cities </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <div class="chip-wrapper">
                    <mat-chip-list>
                      <mat-chip class="chip" *ngFor="let place of element.places">
                        {{place.name}}
                        <mat-icon class="icon-display" fontSet="material-icons-outlined" *ngIf="place.type === 'location'">location_on</mat-icon>
                        <mat-icon class="icon-display" fontSet="material-icons-outlined" *ngIf="place.type === 'restaurant'">restaurant</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div></mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
          <div *ngIf=" nextLink != '' " class="row d-flex justify-content-center m-3">
            <div class="btn btn-outline-primary rounded-pill m-1" (click)="getReviews(true)">Show 25 more</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
