<div>
  <p class="text-primary title">Update Password</p>

  <form>
    <div class="container">
      <div class="row">
        <mat-form-field>
          <mat-label>Password </mat-label>
          <input matInput type="password" [formControl]="new_password" />
          <mat-error
            *ngIf="this.new_password.value.length === 0">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="updatePassword()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn text-white mt-2 mr-1">
      Update Password
    </button>
    <button (click)="closePopup()" class="btn custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>
