import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackbarType } from 'src/app/views/partials/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

    openSnackBar(title: string, message: string, type: SnackbarType, delay: number = 5000): void {
      this.snackBar.openFromComponent<SnackBarComponent>(SnackBarComponent, {
        data: {
          type: type,
          title: title,
          message: message
        }
        ,
        panelClass: "snack-bar-host",
        duration: delay
      });
    }

}
