import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/enums/user-role';
import { Global } from 'src/app/shared/global';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor (
    private userService: UserService,
    private router: Router
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (Global.user) {
      if (this.checkRole(route?.data?.role)) {
        return true;
      } else {
        return this.router.parseUrl(this.getBestUrl());
      }
    }

    else {
      return this.userService.getUserInfo()
        .pipe(
          map(userInfo => {
            Global.user = userInfo;
            if (this.checkRole(route?.data?.role)) {
              return true;
            }

            return this.router.parseUrl(this.getBestUrl());
          })
        );
    }

  }

  private checkRole(userRole?: UserRole): boolean {
    const role = userRole ?? null;

    if (role === null) {
      return true;
    }
    const isValidRole = Global.user.role === role;
    return isValidRole;
  }

  private getBestUrl(): string {
    if (Global.user.role === UserRole.Client) {
      return '/tour';
    }
    else {
      return '/client';
    }
  }

}
