<div>
  <p class="text-primary title">Delete Tour Operator</p>

  <div class="container">
    <div class="row">
      Are you sure you want to delete: {{data.tourOperator.name}}?
    </div>
  </div>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="deleteTourOperator()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn text-white mt-2 mr-1">
      Delete
    </button>
    <button (click)="closePopup()" class="btn custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>