import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { RoleGuard } from './core/guard/role.guard';
import { UserRole } from './enums/user-role';
import { AuthModule } from './views/pages/auth/auth.module';
import { SideNavComponent } from './views/partials/side-nav/side-nav.component';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: SideNavComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: 'dashboard', canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: "tour", canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import("./views/pages/tour/tour.module").then(m => m.TourModule) },
      { path: "tour-operator", canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import("./views/pages/tour-operators/tour-operators.module").then(m => m.TourOperatorsModule) },
      { path: "tour-operator-score", canActivate: [RoleGuard], data: { role: UserRole.Admin }, loadChildren: () => import("./views/pages/tour-operators-score/tour-operators-score.module").then(m => m.TourOperatorsScoreModule) },
      // { path: 'profile', canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import('./views/pages/profile/profile.module').then(m => m.ProfileModule) },
      // { path: 'report', canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import('./views/pages/report/report.module').then(m => m.ReportModule) },
      // { path: "schedule", canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import('./views/pages/schedule/schedule.module').then(m => m.ScheduleModule) },
      // { path: "email", canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import('./views/pages/email/email.module').then(m => m.EmailModule) },
      { path: "user", canActivate: [RoleGuard], data: { role: UserRole.Client }, loadChildren: () => import('./views/pages/user/user.module').then(m => m.UserModule) },
      { path: "client", canActivate: [RoleGuard], data: { role: UserRole.Admin }, loadChildren: () => import('./views/pages/clients/clients.module').then(m => m.ClientsModule) },
      { path: '**', redirectTo: 'client', pathMatch: 'full' },
      { path: '', redirectTo: 'client', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [
    AuthModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
