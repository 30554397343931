<div class="container-padding">
    <div class="col">

      <div class="row d-flex justify-content-between">
        <div class="col-12">
          <div class="card bg-white rounded">
            <div class="card-body col-12">
              <div class="card-title row d-flex justify-content-between">
                <div class="col d-flex">
                  <h2>Keywords</h2>
                </div>
                <div class="col d-flex justify-content-end">
                  <div [formGroup]="selectDateForm" class="form-group ml-1">
                    <mat-form-field appearance="outline">
                      <mat-label>Select a prediod</mat-label>
                      <mat-select autocomplete="off" value="" (selectionChange)="selectChange($event)" formControlName="dateSelect">
                        <mat-option value="">
                        </mat-option>
                        <mat-option value="12">
                          Last Year
                        </mat-option>
                        <mat-option value="1">
                          Last Month
                        </mat-option>
                        <mat-option value="7">
                          Last Week
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="form-group ml-1">
                    <mat-form-field appearance="outline">
                      <mat-label>Date From</mat-label>
                      <input matInput (focus)="picker1.open()" [value]="selectedDateFromFilter" (dateChange)="dateFromChange($event, true)" [matDatepicker]="picker1" [readonly]="true" autocomplete="off">
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-group ml-1">
                    <mat-form-field appearance="outline">
                      <mat-label>Date To</mat-label>
                      <input matInput (focus)="picker2.open()" [value]="selectedDateToFilter" (dateChange)="dateToChange($event)" [matDatepicker]="picker2" [readonly]="true" autocomplete="off">
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-group ml-1">
                    <mat-form-field appearance="outline">
                      <mat-select class="agency-field" autocomplete="off" [(value)]="selectedAgenciesFilter" (selectionChange)="setAgencyFilter($event.value)">
                        <mat-option [value]="'all'" selected>
                          <img class="img-fluid mr-2" style="max-width:20px;" [src]="'../../../../assets/logos/all.png'">
                          Everything
                        </mat-option>
                        <mat-option *ngFor="let agency of agencies" [value]="agency.value">
                          <ng-container matOption>
                            <img class="img-fluid mr-2" style="max-width:20px;" src="../../../../assets/logos/{{agency.value}}.png">
                            {{agency.name}}
                          </ng-container>
                        </mat-option>
                      </mat-select>
                      <div class="selected-icon">
                        <img class="img-fluid" style="max-width:20px;" [src]="'../../../../assets/logos/' + selectedAgenciesFilter + '.png'" >
                      </div>
                    </mat-form-field>
                  </div>
                </div>
              </div>


              <div class="row d-flex justify-content-around">
                <div class="col card mx-1">
                  <h3 class="mt-3"><b>Affected negatively</b></h3>
                  <mat-table #table [dataSource]="dataNegativeSource" matSort #sort1="matSort" matSortActive="word" matSortDirection="asc"
                             class="text-danger">
                    <ng-container matColumnDef="word">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Word</mat-header-cell>
                      <mat-cell *matCellDef="let element" class="text-danger">
                        {{element.word}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="number_of_repetitions">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> N. of negatives </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.number_of_repetitions}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="number_of_repetitions_variation">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Repetition Variations</mat-header-cell>
                      <mat-cell *matCellDef="let element" class="text-muted container justify-content-between"> <small>{{element.number_of_repetitions_variation}}</small> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="impact">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Impact </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="">
                        <div class="font-weight-bold">{{element.impact}}</div>
                        <mat-progress-bar mode="determinate" value="{{element.impact}}" class="col-8" color="warn"></mat-progress-bar>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                </div>

                <div class="col card mx-1">
                  <h3 class="mt-3"><b>Affected positively</b></h3>
                  <mat-table #table2 [dataSource]="dataPositiveSource" matSort #sort2="matSort" matSortActive="word" matSortDirection="asc"
                             class="">
                    <ng-container matColumnDef="word">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Word</mat-header-cell>
                      <mat-cell *matCellDef="let element" class="text-success">
                        {{element.word}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="number_of_repetitions">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> N. of positives  </mat-header-cell>
                      <mat-cell *matCellDef="let element">{{element.number_of_repetitions}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="number_of_repetitions_variation">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Repetition Variations </mat-header-cell>
                      <mat-cell *matCellDef="let element" class="text-muted container justify-content-between"> <small>{{element.number_of_repetitions_variation}}</small> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="impact">
                      <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Impact </mat-header-cell>
                      <mat-cell *matCellDef="let element" class=" row">
                        <div class="font-weight-bold">
                          {{element.impact}}
                        </div>
                        <mat-progress-bar mode="determinate" value="{{element.impact}}" class="col-8"></mat-progress-bar>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
