import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { TourOperatorService } from 'src/app/core/services/tour-operator.service';
import { TourOperator } from 'src/app/models/tour-operator';

@Component({
  selector: 'app-tour-operators-delete-popup',
  templateUrl: './tour-operators-delete-popup.component.html',
  styleUrls: ['./tour-operators-delete-popup.component.scss']
})
export class TourOperatorsDeletePopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  constructor (
    public dialogRef: MatDialogRef<TourOperatorsDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tourOperator: TourOperator; },
    private tourOperatorService: TourOperatorService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
  }

  deleteTourOperator(): void {

    this.loading = true;

    this.tourOperatorService.deleteTourOperator(this.data.tourOperator.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
