import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ExportEmailService } from 'src/app/core/services/export-email.service';
import { ExportScheduleService } from 'src/app/core/services/export-schedule.service';
import { Email } from 'src/app/models/email';

@Component({
  selector: 'app-schedule-create-popup',
  templateUrl: './schedule-create-popup.component.html',
  styleUrls: ['./schedule-create-popup.component.scss']
})
export class ScheduleCreatePopupComponent implements OnInit {

  dateList = [
    { id: "1", value: "1st" },
    { id: "2", value: "2nd" },
    { id: "3", value: "3rd" },
    { id: "4", value: "4th" },
    { id: "5", value: "5th" },
    { id: "6", value: "6th" },
    { id: "7", value: "7th" },
    { id: "8", value: "8th" },
    { id: "9", value: "9th" },
    { id: "10", value: "10th" },
    { id: "11", value: "11th" },
    { id: "12", value: "12th" },
    { id: "13", value: "13th" },
    { id: "14", value: "14th" },
    { id: "15", value: "15th" },
    { id: "16", value: "16th" },
    { id: "17", value: "17th" },
    { id: "18", value: "18th" },
    { id: "19", value: "19th" },
    { id: "20", value: "20th" },
    { id: "21", value: "21st" },
    { id: "22", value: "22nd" },
    { id: "23", value: "23rd" },
    { id: "24", value: "24th" },
    { id: "25", value: "25th" },
    { id: "26", value: "26th" },
    { id: "27", value: "27th" },
    { id: "28", value: "28th" },
    { id: "29", value: "29th" },
    { id: "30", value: "30th" },
    { id: "31", value: "31st" }
  ];

  loading = false;
  responded = false;
  errorDuringResponse = false;

  emailList: Email[];

  createScheduleForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null, { validators: [Validators.required] }),
    schedule: new FormGroup({
      minutes: new FormControl("00"),
      hours: new FormControl("00"),
      dayOfMonth: new FormControl("*"),
      month: new FormControl("*"),
      dayOfWeek: new FormControl("*")
    }),
    search_factor: new FormControl(null, { validators: [Validators.required] }),
    format: new FormControl(null, { validators: [Validators.required] }),
    date_range_export: new FormControl(null, { validators: [Validators.required] }),
    email_addresses: new FormControl(null, { validators: [Validators.required] })
  });

  scheduleTimeForm = this.createScheduleForm.get("schedule") as FormGroup;

  scheduleTypeControl = new FormControl(0);
  scheduleTimeControl = new FormControl("", { validators: [Validators.required] });

  constructor (
    public dialogRef: MatDialogRef<ScheduleCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { schedule: any; },
    private scheduleService: ExportScheduleService,
    private emailService: ExportEmailService,
    private dialog: MatDialog
  ) {
    if (data.schedule) {
      this.createScheduleForm.get("id")?.setValue(data.schedule.id);
      this.createScheduleForm.get("name")?.setValue(data.schedule.name);
      this.createScheduleForm.get("search_factor")?.setValue(data.schedule.search_factor);
      this.createScheduleForm.get("format")?.setValue(data.schedule.format);
      this.createScheduleForm.get("date_range_export")?.setValue(data.schedule.date_range_export);
      this.createScheduleForm.get("email_addresses")?.setValue(data.schedule.email_addresses);

      const schedule = data.schedule.schedule.split(" ");

      this.scheduleTimeForm.get("minutes")?.setValue(schedule[0]);
      this.scheduleTimeForm.get("hours")?.setValue(schedule[1]);
      this.scheduleTimeForm.get("dayOfMonth")?.setValue(schedule[2]);
      this.scheduleTimeForm.get("month")?.setValue(schedule[3]);
      this.scheduleTimeForm.get("dayOfWeek")?.setValue(schedule[4]);

      let minutes = schedule[0];
      if (minutes.length < 2) {
        minutes = "0" + minutes;
      }
      let hours = schedule[1];
      if (hours.length < 2) {
        hours = "0" + hours;
      }

      this.scheduleTimeControl.setValue(hours + ":" + minutes);

      if (schedule[4] === "1-5") {
        this.scheduleTypeControl.setValue(0);
      }
      else if (Number(schedule[4])) {
        this.scheduleTypeControl.setValue(1);
      }
      else {
        this.scheduleTypeControl.setValue(2);
      }

    }

    this.scheduleTypeControl.valueChanges.subscribe({
      next: value => {
        if (value === 0) {
          this.scheduleTimeForm.get("dayOfWeek")?.setValue("1-5");
          this.scheduleTimeForm.get("dayOfMonth")?.setValue("*");
        }
        else if (value === 1) {
          this.scheduleTimeForm.get("dayOfMonth")?.setValue("*");
          this.scheduleTimeForm.get("dayOfWeek")?.setValue("*");
        }
        else if (value === 2) {
          this.scheduleTimeForm.get("dayOfWeek")?.setValue("*");
        }
      }
    });
  }

  ngOnInit(): void {
    this.emailService.exportEmails().subscribe({
      next: emails => {
        this.emailList = emails;
      }
    });
  }

  fromMondayToFridayCheck(): void {
    this.scheduleTimeForm.get("1-5")?.setValue("*");
  }

  createSchedule(): void {

    if (this.scheduleTimeControl.invalid || this.createScheduleForm.invalid) {
      this.scheduleTimeControl.markAllAsTouched();
      this.createScheduleForm.markAllAsTouched();
      return;
    }

    let schedule = this.createScheduleForm.value;

    this.scheduleTimeForm.get("hours").setValue(this.scheduleTimeControl.value.split(":")[0]);
    this.scheduleTimeForm.get("minutes").setValue(this.scheduleTimeControl.value.split(":")[1]);

    schedule.schedule = this.scheduleTimeForm.value.minutes + " " +
      this.scheduleTimeForm.value.hours + " " +
      this.scheduleTimeForm.value.dayOfMonth + " " +
      this.scheduleTimeForm.value.month + " " +
      this.scheduleTimeForm.value.dayOfWeek;

    this.loading = true;

    if (this.data.schedule) {
      this.scheduleService.updateSchedule(schedule)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: response => {
            this.dialogRef.close(response);
          }
        });
    }
    else {
      this.scheduleService.createSchedule(schedule)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        ).subscribe({
          next: response => {
            this.dialogRef.close(response);
          }
        });
    }
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
