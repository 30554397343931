import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from "@angular/material/sort";
import { Review } from 'src/app/core/models/review.model';
import { ReviewService } from 'src/app/core/services/review.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import {ReviewPopupComponent} from "../review-popup/review-popup.component";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Agencies } from 'src/app/core/models/agencies.model';


const API_REVIEW : string = environment.baseLink + '/v1/api/reviews/'

@Component({
  selector: 'app-live-review-list',
  templateUrl: './live-review-list.component.html',
  styleUrls: ['./live-review-list.component.scss']
})

export class LiveReviewListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @Output() refreshAll = new EventEmitter();

  reviews: Review[];
  displayedColumns: string[] = ["date", "source_stream_table", "Agency", "Review", "rating", "sentiment_text", "Actions"]
  dataSource = new MatTableDataSource();
  nextLink: string = '';
  showFilter: boolean = false;
  public isRotating = false;
  agencies: string[] = ["viator", "airbnb", "tripadvisor", "expedia", "klook", "civitatis", "getyourguide", "facebook", "google", "instagram", "linkedin"]
  filterForm: FormGroup
  defaultSort = 'asc'

  constructor(
    private reviewService: ReviewService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getReviews()
    this.initFilterForm()
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  refreshEverything() {
    this.isRotating = true;
    this.getReviews(false, true)
    this.refreshAll.emit();
    setTimeout(() => {
      this.isRotating = false;
    }, 1000);
  }

  getReviews(next=false, refresh=false) {
    let url = API_REVIEW+'?page_size=25'
    if (next) {
      url = this.nextLink
    }
    this.reviewService.get25Reviwes(url).subscribe(data => {
      // this.dataSource.data = data.results;
      this.nextLink = data.next ? data.next.replace('http://13.232.79.90', 'http://13.232.79.90:8000') : '';
      data.results.forEach((review: Review) => {
        if (!review.review_text) {
          review.review_text = ''
        }
        review.small_text = review.review_text.substring(0,40) + '...';

        review.source_stream_table = review.source_stream.name;
        review.stars = Array(review.rating).fill('★');
        switch (review.sentiment){
          case 3:{
            review.color = "success";
            review.sentiment_text = "Positive"
            break;
          }
          case 2:{
            review.color = "warning";
            review.sentiment_text = "Neutral"
            break;
          }
          case 1:{
            review.color = "danger";
            review.sentiment_text = "Negative"
            break;
          }
          default:{
            review.color = "warning";
            break;
          }
        }
      })
      if (refresh) {
        this.dataSource.data = data.results;

      } else {
        this.dataSource.data = this.dataSource.data.concat(data.results);
      }
    })
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      start_date: ['', Validators.compose([
      ])
			],
      end_date: ['', Validators.compose([
      ])
			],
      rating: ['', Validators.compose([
      ])
			],
      agency: ['', Validators.compose([
      ])
			],
      sentimentPositive: [false, Validators.compose([
      ])
			],
      sentimentNegative: [false, Validators.compose([
      ])
			],
      sentimentNeutral: [false, Validators.compose([
      ])
			],
      sort_by: ['', Validators.compose([
      ])
      ],
    })
  }

  applyFilters(): void {
    const controls = this.filterForm.controls;
    let sentiment = [];
    let filter = {
      start_date: controls.start_date.value,
      end_date: controls.end_date.value,
      max_rating: controls.rating.value,
      min_rating: controls.rating.value,
      sort_by: controls.sort_by.value,
      source_stream: controls.agency.value || "",
      sentiment: ""
    };

    // format data for the be
    if(filter.start_date != ""){
      const offset = filter.start_date.getTimezoneOffset()
      filter.start_date = new Date(filter.start_date.getTime() - (offset*60*1000))
      filter.start_date = filter.start_date.toISOString().split('T')[0]
    }

    if(filter.end_date != ""){
      const offset = filter.end_date.getTimezoneOffset()
      filter.end_date = new Date(filter.end_date.getTime() - (offset*60*1000))
      filter.end_date = filter.end_date.toISOString().split('T')[0]
    }

    if(controls.sentimentNegative.value)
      sentiment.push("1");
    if(controls.sentimentNeutral.value)
      sentiment.push("2");
    if(controls.sentimentPositive.value)
      sentiment.push("3");

    filter.sentiment = sentiment.join(",");

    console.log(filter)
    this.reviewService.getReviewsWithFilter(filter).subscribe(data => {
      this.reviews = data.results;
      this.dataSource.data = data.results;
      this.nextLink = data.next ? data.next.replace('http://13.232.79.90', 'http://13.232.79.90:8000') : '';
      this.dataSource.data.forEach((review: Review) => {
        if (!review.review_text) {
          review.review_text = ''
        }
        review.small_text = review.review_text.substring(0,40) + '...';
        review.source_stream_table = review.source_stream.name;
        review.stars = Array(review.rating).fill('★');
        switch (review.sentiment){
          case 3:{
            review.color = "success";
            review.sentiment_text = "Positive"
            break;
          }
          case 2:{
            review.color = "warning";
            review.sentiment_text = "Neutral"
            break;
          }
          case 1:{
            review.color = "danger";
            review.sentiment_text = "Negative"
            break;
          }
          default:{
            review.color = "warning";
            break;
          }
        }
      })
    })
  }

  openReviewPopup(review: any) {
    this.dialog.open(ReviewPopupComponent, {
      data: { review },
      width: '700px',
    });
  }

  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  resetFilters(): void{
    this.initFilterForm();
    this.getReviews();
  }

}
