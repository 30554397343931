<div>
  <p class="text-primary title">
    {{data.user? "Edit User" : "Create User"}}
  </p>

  <form [formGroup]="createUserForm">

    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error *ngIf="createUserForm.get('name').invalid && createUserForm.get('name').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" />
          <mat-error *ngIf="createUserForm.get('email').invalid && createUserForm.get('email').errors['required']">This
            field is rquired</mat-error>
          <mat-error *ngIf="createUserForm.get('email').invalid && createUserForm.get('email').errors['email']">Please
            Enter a valid Email</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">

      <div class="col-6">
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-error
            *ngIf="createUserForm.get('password').invalid && createUserForm.get('password').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>User Type</mat-label>
          <mat-select formControlName="user_type">
            <ng-container *ngFor="let userType of UserType | enumToList">
              <mat-option [value]="userType.id">{{userType.value}}</mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="createUserForm.get('user_type').invalid && createUserForm.get('user_type').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>
    </div>

  </form>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="createUser()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn bg-primary text-white mt-2 mr-1">
      {{data.user? "Update" : "Create"}}
    </button>
    <button (click)="closePopup()" class="btn btn-outline-primary custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>