<div>
  <p class="text-primary title">Delete Schedule</p>

  <div class="container">
    <div class="row">
      Are you sure you want to delete: Schedule - {{data.schedule.name}}?
    </div>
  </div>


  <div class="d-flex justify-content-start mt-3">
    <div (click)="deleteSchedule()" [ngClass]="{'loading pr-5': loading}"
         class="btn custom-btn bg-primary text-white mt-2 mr-1">
      Delete
    </div>
    <button (click)="closePopup()" class="btn btn-outline-primary custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>

  </div>

</div>
