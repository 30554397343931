import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarComponent } from './snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,

    MatSnackBarModule
  ],
  declarations: [SnackBarComponent]
})
export class SnackBarModule { }
