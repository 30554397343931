<div class="container-padding">
    <div class="col">
        <div class="row m-3">
            <h3>Latest Reviews</h3>
        </div>

        <div class="row d-flex justify-content-between">
          <div class="col-4">
            <div class="card bg-white rounded latest-review" >
                <div class="card-body">
                    <h5 class="card-title text-success">Promoters</h5>
                    <hr>
                    <div *ngFor="let review of positiveReviews" class="container">
                        <div class="row row-sentiment">
                            <div class="col-2">
                              <img *ngIf="!review.img" class="img-fluid mr-1" src="../../../../assets/logos/img.png" >
                              <img *ngIf="review.img" class="img-fluid mr-1" [src]="review.img" >
                            </div>
                            <div class="col-10">
                              <p *ngIf="review.review_text && review.review_text.length <= 80">
                                {{review.review_text}} &nbsp;
                              </p>
                              <p *ngIf="review.review_text && review.review_text.length > 80">
                                {{review.review_text | truncate:80:true:'...'}}
                              </p>
                              <p *ngIf="review.stars" class="text-success">{{review.stars.join(' ')}}</p>
                              <p *ngIf="!review.stars" class="text-success">&nbsp;</p>
                            </div>
                        </div>
                    </div>
                  </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card bg-white rounded latest-review" >
                <div class="card-body">
                    <h5 class="card-title text-danger">Detractors</h5>
                    <hr>
                    <div *ngFor="let review of negativeReviews" class="container">
                        <div class="row row-sentiment">
                            <div class="col-2">
                              <img *ngIf="!review.img" class="img-fluid mr-1" src="../../../../assets/logos/img.png" >
                              <img *ngIf="review.img" class="img-fluid mr-1" [src]="review.img" >
                            </div>
                            <div class="col-10">
                              <p *ngIf="review.review_text && review.review_text.length <= 80">
                                {{review.review_text}} &nbsp;
                              </p>
                              <p *ngIf="review.review_text && review.review_text.length > 80">
                                {{review.review_text | truncate:80:true:'...'}}
                              </p>
                              <p *ngIf="review.stars" class="text-danger">{{review.stars.join(' ')}}</p>
                              <p *ngIf="!review.stars" class="text-danger">{{' '}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card bg-white rounded latest-review" >
              <div class="card-body">
                    <h5 class="card-title text-warning">Passive</h5>
                    <hr>
                    <div *ngFor="let review of neutralReviews" class="container">
                        <div class="row row-sentiment">
                            <div class="col-2">
                              <img *ngIf="!review.img" class="img-fluid mr-1" src="../../../../assets/logos/img.png" >
                              <img *ngIf="review.img" class="img-fluid mr-1" [src]="review.img" >
                            </div>
                            <div class="col-10">
                                <p *ngIf="review.review_text && review.review_text.length <= 80">
                                  {{review.review_text}} &nbsp;
                                </p>
                                <p *ngIf="review.review_text && review.review_text.length > 80">
                                  {{review.review_text | truncate:80:true:'...'}}
                                </p>
                                <p *ngIf="review.stars" class="text-warning">{{review.stars.join(' ')}}</p>
                                <p *ngIf="!review.stars" class="text-warning">&nbsp;</p>
                            </div>
                        </div>
                    </div>
              </div>
            </div>

        </div>
    </div>
</div>
