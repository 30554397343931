import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { TourService } from 'src/app/core/services/tour.service';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/models/user';
import {SnackbarType} from "../snack-bar/snack-bar.component";
import {SnackBarService} from "../../../core/services/snack-bar.service";

@Component({
  selector: 'app-tour-upload-popup',
  templateUrl: './tour-upload-popup.component.html',
  styleUrls: ['./tour-upload-popup.component.scss']
})
export class TourUploadPopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  file?: File;
  fileSize?: number;

  constructor (
    public dialogRef: MatDialogRef<TourUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User; },
    private tourService: TourService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {

  }

  ngOnInit(): void {
  }

  onFileUpload(files: FileList): void {
    if (files.length < 1) return;

    this.file = files[0];
    const fileSizeInBytes = this.file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert bytes to MB
    this.fileSize = +fileSizeInMB.toFixed(2);
  }

  uploadFile(): void {

    if (!this.file) return;

    this.loading = true;

    this.tourService.uploadTour(this.file)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
          this.snackBarService.openSnackBar("File Uploaded", "File uploaded successully, we are currently examinating the file, please come back in 5-10 minutes to view the results.", SnackbarType.Success, 10000);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
