<div class="container-padding">
    <div class="col">

        <div class="row d-flex justify-content-between">
            <div class="col-12">
                <div class="card bg-white rounded">
                    <div class="card-body">
                        <div class="card-title d-flex justify-content-between">
                            <div class="col-2 d-flex">
                              <h4>Dashboard</h4>
                            </div>
                            <div class="col-8 d-flex"></div>
                            <div class="col-2 d-flex justify-content-end">
                                <p class="rounded-pill p-1 pl-2 pr-2">Last sync</p>
                                <p class="rounded-pill text-white bg-button p-1  pl-2 pr-2">{{statistics.last_sync.toLocaleTimeString()}} hr</p>
                            </div>
                        </div>

                        <div class="row d-flex justify-content-around">
                            <div class="col-2 p-0 px-2">
                                <div class="card rounded border-light">
                                    <div class="card-body rounded card-gradient">
                                        <div class="card-title text-primary d-flex justify-content-between mt-1 align-middle">
                                            <h6>This month</h6>
                                            <mat-icon matTooltip="refresh" class="text-muted">help_outline</mat-icon>
                                        </div>
                                        <div>
                                            <h1 class="text-button m-0 p-0">{{statistics.reviews.this_month}}</h1>
                                            <p class="m-0 p-0 text-primary">Reviews</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 p-0 px-2">
                                <div class="card rounded border-light">
                                    <div class="card-body rounded card-gradient">
                                        <div class="card-title text-primary d-flex justify-content-between mt-1 align-middle">
                                            <h6>Promoters</h6>
                                            <mat-icon matTooltip="refresh" class="text-muted">help_outline</mat-icon>
                                        </div>
                                        <div>
                                            <h1 class="text-button m-0 p-0">{{statistics.reviews.positive}}</h1>
                                            <p class="m-0 p-0 text-primary">Reviews</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 p-0 px-2">
                                <div class="card rounded border-light">
                                    <div class="card-body rounded card-gradient">
                                        <div class="card-title text-primary d-flex justify-content-between mt-1 align-middle">
                                            <h6>Detractors</h6>
                                            <mat-icon matTooltip="refresh" class="text-muted">help_outline</mat-icon>
                                        </div>
                                        <div>
                                            <h1 class="text-button m-0 p-0">{{statistics.reviews.negative}}</h1>
                                            <p class="m-0 p-0 text-primary">Reviews</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                          <div class="col-2 p-0 px-2">
                                <div class="card rounded border-light">
                                    <div class="card-body rounded card-gradient">
                                        <div class="card-title text-primary d-flex justify-content-between mt-1 align-middle">
                                            <h6>Passive</h6>
                                            <mat-icon matTooltip="refresh" class="text-muted">help_outline</mat-icon>
                                        </div>
                                        <div>
                                            <h1 class="text-button m-0 p-0">{{statistics.reviews.neutral}}</h1>
                                            <p class="m-0 p-0 text-primary">Reviews</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 p-0 px-2">
                                <div class="card rounded border-light">
                                    <div class="card-body rounded card-gradient">
                                        <div class="card-title text-primary d-flex justify-content-between mt-1 align-middle">
                                            <h6>Overall</h6>
                                            <mat-icon matTooltip="refresh" class="text-muted">help_outline</mat-icon>
                                        </div>
                                        <div>
                                            <h1 class="text-button m-0 p-0">{{statistics.reviews.overall}}</h1>
                                            <p class="m-0 p-0 text-primary">Reviews</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 p-0 px-2">
                                <div class="card rounded border-light">
                                    <div class="card-body rounded card-gradient">
                                        <div class="card-title text-primary d-flex justify-content-between mt-1 align-middle">
                                            <h6>Responded</h6>
                                            <mat-icon matTooltip="refresh" class="text-muted">help_outline</mat-icon>
                                        </div>
                                        <div>
                                            <h1 class="text-button m-0 p-0">{{statistics.reviews.responded}}</h1>
                                            <p class="m-0 p-0 text-primary">Reviews</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

    </div>
</div>
