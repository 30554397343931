import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggedInUser } from '../models/logged-in-user.model';
import { UserRole } from 'src/app/enums/user-role';


const API_LOGIN: string = environment.baseLink + '/v1/auth/login/';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor (private http: HttpClient) { }

  login(email: string, password: string, totp_code: string = '123456'): Observable<LoggedInUser> {
    let httpHeaders = new HttpHeaders();
    console.log(email, password, totp_code);
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.post<any>(API_LOGIN, { email, password, totp_code }, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [false];
        })
      );
  }

  FakeLogin(email: string, password: string): Observable<LoggedInUser> {
    // let fakeUsers: User[] = [
    //   {
    //     id: 1,
    //     name: "normale",
    //     surname: "normale",
    //     email: "normale@normale",
    //     password: "test321",
    //     isAdmin: false,
    //     isKitchen: false,
    //   },
    //   {
    //     id: 2,
    //     name: "admin",
    //     surname: "admin",
    //     email: "admin@admin",
    //     password: "test321",
    //     isAdmin: true,
    //     isKitchen: false,
    //   },
    //   {
    //     id: 3,
    //     name: "cucina",
    //     surname: "cucina",
    //     email: "cucina@cucina",
    //     password: "test321",
    //     isAdmin: false,
    //     isKitchen: true,
    //   },
    //   {
    //     id: 4,
    //     name: "a",
    //     surname: "a",
    //     email: "a@a",
    //     password: "aaaa",
    //     isAdmin: false,
    //     isKitchen: true,
    //   },
    // ];

    var foundUser: LoggedInUser = {
      id: -1,
      name: "",
      access: "",
      bio: "",
      birthday: new Date().toISOString(),
      company_name: "",
      date_joined: new Date().toISOString(),
      email: "",
      gender: 0,
      isKitchen: false,
      office_phone: "",
      phone: "",
      profile_image_path: "",
      refresh: "",
      role: UserRole.Admin,
      timezone: "",
      username: ""
    };
    // foundUser.id = -1
    // fakeUsers.forEach(user => {
    //     if(user.email == email && user.password == password){
    //       foundUser = user;
    //     }
    // });


    return of(foundUser);
  }


  logout(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
  }

}
