import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";

const API_COMPARATIVE_GRAPHICS: string = environment.baseLink + '/v1/api/comparative-graphics/';
const API_REVIEWS_LOCATION: string = environment.baseLink + '/v1/api/reviews-location/';
const API_SUMMARY_EVOLUTION: string = environment.baseLink + '/v1/api/summary-evolution/';
const API_SUMMARY_EVOLUTION_TOTALS: string = environment.baseLink + '/v1/api/summary-evolution-totals/';
const API_REPUTATION: string = environment.baseLink + '/v1/api/reputation/';
const API_KEYWORDS: string = environment.baseLink + '/v1/api/keywords/';
const API_TOURS: string = environment.baseLink + '/v1/api/tours/';
const API_COUNTRIES: string = environment.baseLink + '/v1/api/countries/';
const API_DOWNLOAD_PDF: string = environment.baseLink + '/v1/api/export-pdf/';
const API_DOWNLOAD_XLSX: string = environment.baseLink + '/v1/api/export-xlsx/';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  filterDateChangeEvent = new EventEmitter();
  agencyChangeEvent = new EventEmitter();

  constructor (private http: HttpClient) { }

  comparativeGraphics(source_stream = null, start_date = null, end_date = null): Observable<any> {
    let q = '?';
    if (start_date) {
      q += `start_date=${start_date}&`;
    }
    if (end_date) {
      q += `end_date=${end_date}&`;
    }
    q += `source_stream=${source_stream}`;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_COMPARATIVE_GRAPHICS + q, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [null];
        })
      );
  }

  reviewsLocation(source_stream = null, start_date = null, end_date = null): Observable<any> {
    let q = '?';
    if (start_date) {
      q += `start_date=${start_date}&`;
    }
    if (end_date) {
      q += `end_date=${end_date}&`;
    }
    q += `source_stream=${source_stream}`;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_REVIEWS_LOCATION + q, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [null];
        })
      );
  }


  summaryEvolution(source_stream = null, start_date = null, end_date = null): Observable<any> {
    let q = '?';
    if (start_date) {
      q += `start_date=${start_date}&`;
    }
    if (end_date) {
      q += `end_date=${end_date}&`;
    }
    q += `source_stream=${source_stream}`;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_SUMMARY_EVOLUTION + q, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [null];
        })
      );
  }

  summaryEvolutionTotal(source_stream = null, start_date = null, end_date = null): Observable<any> {
    let q = '?';
    if (start_date) {
      q += `start_date=${start_date}&`;
    }
    if (end_date) {
      q += `end_date=${end_date}&`;
    }
    q += `source_stream=${source_stream}`;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_SUMMARY_EVOLUTION_TOTALS + q, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [null];
        })
      );
  }

  reputationManagementData(start_date = null, end_date = null): Observable<any> {
    let q = '?';
    if (start_date) {
      q += `start_date=${start_date}&`;
    }
    if (end_date) {
      q += `end_date=${end_date}`;
    }

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_REPUTATION + q, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [null];
        })
      );

  }

  keywordsPositiveData(source_stream = null, start_date = null, end_date = null): Observable<any> {
    let q = '?';
    if (start_date) {
      q += `start_date=${start_date}&`;
    }
    if (end_date) {
      q += `end_date=${end_date}&`;
    }
    q += `source_stream=${source_stream}`;

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_KEYWORDS + q, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [null];
        })
      );

  }

  getTours(source_stream = null): Observable<any> {
    let q = '?';
    if (source_stream) {
      q += `source_stream=${source_stream}&`;
    }

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_TOURS + q, { headers: httpHeaders })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(err => {
          // TODO better error handling
          return [null];
        })
      );

  }

  downloadPDF(type, search_factor, start_date, end_date) {
    let url = '';

    switch (type) {
      case 'pdf':
        url = API_DOWNLOAD_PDF;
        break;
      case 'xlsx':
        url = API_DOWNLOAD_XLSX;
        break;
    }

    let q = '?';
    if (start_date) {
      q += `start_date=${start_date}&`;
    }
    if (end_date) {
      q += `end_date=${end_date}&`;
    }
    q += `search_factor=${search_factor}`;

    this.http.get(url + q, { responseType: 'blob' }).subscribe(response => {
      this.saveFile(response, type);
    });
  }

  saveFile(response: Blob, type: string) {
    const blob = new Blob([response], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'review_report.' + type; // Sostituisci con il nome desiderato per il file
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }
}
