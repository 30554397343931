import { Component, OnInit, ViewChild } from '@angular/core';
import { dataMock } from './mock_data';
import { ReportService } from 'src/app/core/services/report.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reputation-management',
  templateUrl: './reputation-management.component.html',
  styleUrls: ['./reputation-management.component.scss']
})
export class ReputationManagementComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  selectedDateFromFilter: Date;
  selectedDateToFilter: Date;
  data: any[] = [];
  displayedColumns: string[] = ["stream", "responces", "to_respond", 'score_variation', "score"]
  dataSource = new MatTableDataSource();
  selectDateForm: FormGroup;

  constructor(
    private reportService: ReportService,
    private fb: FormBuilder,
  ) {
    this.reportService.filterDateChangeEvent.subscribe(event => {
      this.selectedDateFromFilter = event.dateFrom
      this.selectedDateToFilter = event.dateTo
      this.selectDateForm.controls['dateSelect'].setValue(event.selectValue);
      this.getFilterData();
    })

    this.selectDateForm = fb.group({
      'dateSelect': ['1'],
    });
  }

  ngOnInit(): void {
    this.reportService.reputationManagementData().subscribe((data: any) => {
        this.data = data
        this.dataSource.data = data
    });
  }

  ngAfterViewInit() {
    let today = new Date();
    this.selectedDateToFilter = new Date();
    today.setMonth(today.getMonth() - 1);
    this.selectedDateFromFilter = today;
    this.dataSource.sort = this.sort;
    this.dataSource.sort = this.sort;
    this.dataSource.sort.active = 'score';
    this.dataSource.sort.direction = 'desc';
  }

  selectChange($event){
    if($event.value == "")
      return;

    let today = new Date();

    if ( $event.value == 7 ){
      today.setDate(today.getDate() - 7 );
    } else {
      today.setMonth(today.getMonth() - $event.value);
    }

    $event.value = today;
    this.dateFromChange($event, false);
  }

  dateFromChange($event, resetSelect){
    this.selectedDateFromFilter = $event.value;

    if(resetSelect){
      this.selectDateForm.controls['dateSelect'].setValue('');
    }

    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter,
      selectValue: this.selectDateForm.controls['dateSelect'].value
    })
    this.getFilterData();
  }

  dateToChange($event){
    this.selectedDateToFilter = $event.value;
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter
    })
    this.getFilterData();
  }

  getFilterData(): void{
    this.reportService.reputationManagementData(this.selectedDateFromFilter.toISOString().split('T')[0], this.selectedDateToFilter.toISOString().split('T')[0]).subscribe((data: any) => {
      this.data = data
      this.dataSource.data = data
    });
  }

}
