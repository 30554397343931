import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { TourOperatorService } from 'src/app/core/services/tour-operator.service';
import { TourService } from 'src/app/core/services/tour.service';
import { TourUrlStream } from 'src/app/enums/tour-url-stream';
import { Tour } from 'src/app/models/tour';
import { TourOperator } from 'src/app/models/tour-operator';
import { TourUrl } from 'src/app/models/tour-url';
import { SnackbarType } from '../snack-bar/snack-bar.component';
import { NewOpraterCreateComponent } from './../new-oprater-create-popup/new-oprater-create-popup.component';



@Component({
  selector: 'app-tour-create-popup',
  templateUrl: './tour-create-popup.component.html',
  styleUrls: ['./tour-create-popup.component.scss']
})
export class TourCreatePopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  TourUrlStream = TourUrlStream;
  tourOperatorList?: TourOperator[];
  categoryData: any;

  createTourForm = new FormGroup({
    id: new FormControl(null),
    tour_operator: new FormControl('', { validators: [Validators.required] }),
    country: new FormControl('', { validators: [Validators.required] }),
    city: new FormControl('', { validators: [Validators.required] }),
    category: new FormControl('', { validators: [Validators.required] }),
    email: new FormControl(''),
    website: new FormControl('', { validators: [Validators.required] }),
    tour_urls: new FormArray([]),
  });

  tourUrlsFormArray: FormArray = this.createTourForm.get('tour_urls') as FormArray;

  displayedColumns: string[] = ['url', 'stream', 'actions'];

  dataSource = new MatTableDataSource();

  constructor (
    public dialogRef: MatDialogRef<TourCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tour?: Tour; },
    private tourService: TourService,
    private tourOperatorService: TourOperatorService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {
    if (data.tour) {
      this.createTourForm.get('id')?.setValue(data.tour.id);
      this.createTourForm.get('tour_operator')?.setValue(data.tour.tour_operator);
      this.createTourForm.get('country')?.setValue(data.tour.country);
      this.createTourForm.get('city')?.setValue(data.tour.city);
      this.createTourForm.get('category')?.setValue(data.tour.category);
      this.createTourForm.get('email')?.setValue(data.tour.email);
      this.createTourForm.get('website')?.setValue(data.tour.website);

      data.tour.tour_urls.forEach(tourUrl => {
        this.addTourUrl(tourUrl);
      });
    }
  }

  ngOnInit(): void {
    this.getTourOperators();

    if (!this.data.tour) {
      this.addTourUrl();
    }

    this.tourService.getCategory().subscribe((data: any) => {
      this.categoryData= data;
    });
  }

  getTourOperators(): void {
    this.tourOperatorService.getTourOperatorsNoPagination().subscribe({
      next: tourOperators => {
        this.tourOperatorList = tourOperators;
      }
    });
  }

  createTour(): void {
    this.createTourForm.markAllAsTouched();
    if (this.createTourForm.invalid) return;

    this.loading = true;

    if (this.data.tour) {
      this.tourService
        .updateTour(this.createTourForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: tourOperator => {
            this.dialogRef.close(tourOperator);
            this.snackBarService.openSnackBar("Tour Updated", "Tour Updated Successully", SnackbarType.Success);
          },
        });
    }
    else {
      this.tourService
        .createTour(this.createTourForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: tourOperator => {
            this.dialogRef.close(tourOperator);
            this.snackBarService.openSnackBar("Tour Created", "Tour Created Successully", SnackbarType.Success);
          },
        });
    }
  }

  addTourUrl(tourUrl?: TourUrl): void {

    let group = new FormGroup({
      url: new FormControl('', { validators: [Validators.required] }),
      stream: new FormControl('', { validators: [Validators.required] }),
    });

    if (tourUrl) {
      group.get('url')?.setValue(tourUrl.url);
      group.get('stream')?.setValue(tourUrl.stream);
    }

    this.tourUrlsFormArray.push(group);

    this.dataSource.data = this.tourUrlsFormArray.controls;
  }

  removeTourUrl(index: number): void {
    this.tourUrlsFormArray.removeAt(index);
    this.dataSource.data = this.tourUrlsFormArray.controls;
  }

  closePopup() {
    this.dialog.closeAll();
  }

  AddNewOparateropenDialog() {
    console.log('helo');
    this.dialog
      .open(NewOpraterCreateComponent, {
        data: {
        },
        width: "15%",
        height: "30%"
      })
      .afterClosed()
      .subscribe({
        next: (_) => {
          if (_ !== undefined) {
          }
        },
      });
  }
}
