<div class="container-padding">
    <div class="col">

      <div class="row d-flex justify-content-between">
        <div class="col-12">
          <div class="card bg-white rounded">
            <div class="card-body col-12">
              <div class="card-title row d-flex justify-content-between">
                <div class="col d-flex">
                  <h2>Manage Reputation</h2>
                </div>
                <div class="col d-flex justify-content-end">
                  <div [formGroup]="selectDateForm" class="form-group ml-1">
                    <mat-form-field appearance="outline">
                      <mat-label>Select a prediod</mat-label>
                      <mat-select autocomplete="off" value="" (selectionChange)="selectChange($event)" formControlName="dateSelect">
                        <mat-option value="">
                        </mat-option>
                        <mat-option value="12">
                          Last Year
                        </mat-option>
                        <mat-option value="1">
                          Last Month
                        </mat-option>
                        <mat-option value="7">
                          Last Week
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="form-group ml-1">
                    <mat-form-field appearance="outline">
                      <mat-label>Date From</mat-label>
                      <input matInput (focus)="picker1.open()" [value]="selectedDateFromFilter" (dateChange)="dateFromChange($event, true)" [matDatepicker]="picker1" [readonly]="true" autocomplete="off">
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-group ml-1">
                    <mat-form-field appearance="outline">
                      <mat-label>Date To</mat-label>
                      <input matInput (focus)="picker2.open()" [value]="selectedDateToFilter" (dateChange)="dateToChange($event)" [matDatepicker]="picker2" [readonly]="true" autocomplete="off">
                      <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-around">
                <mat-table #table [dataSource]="dataSource" matSort #sort1="matSort" matSortActive="stream" matSortDirection="asc"
                  class="">

                  <ng-container matColumnDef="stream">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Source</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img class="img-fluid mr-2" style="max-width:5%;"
                          src="../../../../assets/logos/{{element.stream}}.png">
                        {{element.stream | titlecase}}
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="responces">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Total Reviews </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.reviews}}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="to_respond">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Total Negative </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="container justify-content-between"> {{element.negative}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="score_variation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary "> Score Variation</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="container justify-content-between">
                      <div class="font-weight-bold" [className]="element.score_variation >= 0 ? 'text-success' : 'text-danger'">{{ element.score_variation >= 0 ? '+' + element.score_variation : element.score_variation }}%</div>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="score">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary justify-content-end"> Score </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="container justify-content-between">
                      <div class="font-weight-bold">{{element.score}}%</div>
                      <mat-progress-bar mode="determinate" value="{{element.score}}" class="col-8"></mat-progress-bar>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
