import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReviewTotal } from '../models/review-total.model';
import { Review } from '../models/review.model';


const API_REVIEW : string = environment.baseLink + '/v1/api/reviews/'
const API_REVIEW_TOTALS : string = environment.baseLink + '/v1/api/reviews-totals/'
const API_NEW_REVIEWS : string = environment.baseLink + '/v1/api/check-sync/'
const API_RESPOND_REVIEWS : string = environment.baseLink + '/v1/api/respond-to-review/'

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  maxId: number = 0;
  isAlertVisible: boolean = false;

  constructor(private http: HttpClient) { }

  get25Reviwes(requestLink: string) : Observable<any>{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(requestLink, {headers: httpHeaders})
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        // TODO better error handling
        return [null];
      })
    );
  }

  getReviewsTotals() : Observable<ReviewTotal>{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_REVIEW_TOTALS, {headers: httpHeaders})
    .pipe(
      map((res: any) => {
        this.maxId = res['last_review_id']
        return res;
      }),
      catchError(err => {
        // TODO better error handling
        return [null];
      })
    );
  }

  getReviwesWithSentiment(sentiment: number) : Observable<Review[]>{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_REVIEW+'?page_size=4&sentiment='+sentiment, {headers: httpHeaders})
    .pipe(
      map((res: any) => {
        return res.results;
      }),
      catchError(err => {
        // TODO better error handling
        return [null];
      })
    );
  }

  getReviewsWithFilter(filter): Observable<any>{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    let params = new HttpParams();
    Object.keys(filter).forEach(key => {
      if(filter[key] != "")
        params = params.set(key, filter[key]);
    })
    return this.http.get<any>(API_REVIEW, {headers: httpHeaders, params: params})
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(err => {
        // TODO better error handling
        return [null];
      })
    );
  }

  checkIfThereAreNewReviews() : Observable<boolean>{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get<any>(API_NEW_REVIEWS+this.maxId+'/', {headers: httpHeaders})
    .pipe(
      map((res: any) => {
        return res.new_data;
      }),
      catchError(err => {
        // TODO better error handling
        return [null];
      })
    );
  }

  reply(review_id, message) : Observable<any> {
    return this.http.post(API_RESPOND_REVIEWS,
      {
        review_id: review_id,
        message: message
      },
      {observe: 'response' }
    );
  }
}
