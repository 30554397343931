import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TourOperatorService } from 'src/app/core/services/tour-operator.service';
import { TourOperator } from 'src/app/models/tour-operator';
import { TourOperatorsCreatePopupComponent } from '../tour-operators-create-popup/tour-operators-create-popup.component';
import { TourOperatorsDeletePopupComponent } from '../tour-operators-delete-popup/tour-operators-delete-popup.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TourUrlStream } from 'src/app/enums/tour-url-stream';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-tour-operator-list',
  templateUrl: './tour-operator-list.component.html',
  styleUrls: ['./tour-operator-list.component.scss'],
})
export class TourOperatorListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'actions'];

  dataSource = new MatTableDataSource<TourOperator>();
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  TourUrlStream = TourUrlStream;
  results = []
  loading = true
  page: number = 1;

  filterGroup = new FormGroup({
    tour_operator: new FormControl('')
  });


  constructor (
    private tourOperatorService: TourOperatorService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.route.queryParams.subscribe({
      next: query => {
        if (query.page) {
          if (query.page > 0) {
            this.page = query.page;
          }
          else {
            this.router.navigate(["."], { relativeTo: this.route, queryParams: { page: 1 } });
            return;
          }
        }
        else {
          this.page = 1;
        }

        this.getOperators();
      }
    });
  }

  ngOnInit() {
    this.filterGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500)
      )
      .subscribe({
        next: value => {
          this.page = 1;
          let params: Params = {
            page: this.page
          };

          for (const key in value) {
            if (value[key] !== null && value[key] !== "") {
              params[key] = value[key];
            }
          }

          this.router.navigate(["."], { relativeTo: this.route, queryParams: params });
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }


  changePage(event: PageEvent): void {
    const newPageIndex = event.pageIndex + 1;
    this.router.navigate(["."], { relativeTo: this.route, queryParams: { page: newPageIndex } });
  }

  getOperators(): void {
    this.tourOperatorService.getTourOperators(this.filterGroup.value, { page: this.page }).subscribe({
      next: (operators) => {
        this.results = operators.results
        this.loading = false
        this.dataSource.data = operators.results;
        this.paginator.length = operators.count;
        this.paginator.pageSize = 25;
        this.paginator.pageIndex = this.page - 1;

        this.table.renderRows();
      },
    });
  }

  openTourOperatorCreatePopup(tourOperator?: TourOperator): void {
    this.dialog
      .open(TourOperatorsCreatePopupComponent, {
        data: {
          tourOperator,
        },
      })
      .afterClosed()
      .subscribe({
        next: (_) => {
          if (_ !== undefined) {
            this.getOperators();
          }
        },
      });
  }

  openTourOperatorDeletePopup(tourOperator: TourOperator): void {
    this.dialog
      .open(TourOperatorsDeletePopupComponent, {
        data: {
          tourOperator,
        },
      })
      .afterClosed()
      .subscribe({
        next: (_) => {
          if (_ !== undefined) {
            this.getOperators();
          }
        },
      });
  }
}
