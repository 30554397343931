<div class="container-padding">
  <div class="col">
    <div class="row justify-content-between">
        <h3>Live Review</h3>

        <div (click)="toggleFilter()" class="btn-outline-primary rounded-pill border border-primary d-flex align-items-center p-2">
            <mat-icon matTooltip="filter" class="mr-1">filter_alt</mat-icon>
            Filter Reports
        </div>
    </div>

    <div class="row">
        <p class="text-muted text-refresh">You are seeing the lates data </p>
        <mat-icon matTooltip="refresh" class="text-success" [class.rotate]="isRotating" (click)="refreshEverything()">autorenew</mat-icon>
    </div>


    <div *ngIf="showFilter" class="row mb-4">
      <div class="card bg-white rounded filters">
        <div class="card-body">
          <div class="card-title d-flex justify-content-between ml-1">
            <h4>Filter Review</h4>
            <div class="btn">
              <mat-icon matTooltip="close" class="text-muted" (click)="toggleFilter()">close</mat-icon>
            </div>
          </div>
          <hr>
          <form class="form" [formGroup]="filterForm" autocomplete="off" novalidate="novalidate">
            <div class="row mt-3 mr-1">
              <div class="form-group col-3">
                By Stars
                <mat-form-field appearance="outline" class="text-warning">
                  <mat-select formControlName="rating"  autocomplete="off">
                    <mat-option class="text-warning" [value]="1">
                      ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="2">
                      ★ ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="3">
                      ★ ★ ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="4">
                      ★ ★ ★ ★
                    </mat-option>
                    <mat-option class="text-warning" [value]="5">
                      ★ ★ ★ ★ ★
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group col-3">
                By Agency
                <mat-form-field appearance="outline">
                  <mat-select formControlName="agency"  autocomplete="off">
                    <mat-option *ngFor="let agency of agencies" [value]="agency">
                      {{agency}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group col-3">
                Date From
                <mat-form-field appearance="outline">
                  <input matInput (focus)="picker1.open()" [matDatepicker]="picker1" [readonly]="true" formControlName="start_date"  autocomplete="off">
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-3">
                Date To
                <mat-form-field appearance="outline">
                  <input matInput (focus)="picker2.open()" [matDatepicker]="picker2" [readonly]="true" formControlName="end_date"  autocomplete="off">
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="form-group col-3">
                Sort By Date
                <mat-form-field appearance="outline">
                  <mat-select formControlName="sort_by" [(ngModel)]="defaultSort" autocomplete="off">
                    <mat-option value="asc">
                      Ascending
                    </mat-option>
                    <mat-option value="desc">
                      Descending
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-around mt-3" >
              <div class="form-group d-flex justify-content-sm-start col-5">
<!--                <p>By sentiment: </p>-->
                <mat-label>By sentiment: </mat-label>

                <mat-checkbox formControlName="sentimentPositive">Promoters</mat-checkbox>
                <mat-checkbox formControlName="sentimentNegative">Detractors</mat-checkbox>
                <mat-checkbox formControlName="sentimentNeutral">Passive</mat-checkbox>
              </div>

              <div class="col">
                <div class="d-flex justify-content-end">
                  <div (click)="resetFilters()" class="btn btn-outline-primary rounded-pill mr-1">
                    Reset
                  </div>
                  <div (click)="applyFilters()" class="btn rounded-pill text-white bg-primary ml-1 mr-3">
                    Save
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- TEST TABLE 2 -->
    <div class="row">
        <mat-table #table [dataSource]="dataSource"
                    matSort
                    #sort1="matSort"
                    matSortActive="Date"
                    matSortDirection="asc"
                    class="">

            <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Date Posted </mat-header-cell>
            <mat-cell *matCellDef="let element" class="border-left-x2 border-{{element.color}}"> {{element.date | date:'dd-MM-yyyy'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="source_stream_table">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">Source</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <a href="{{element.review_url}}" target="_blank">
                    <img class="img-fluid mr-1" style="max-width:15%;" src="../../../../assets/logos/{{element.source_stream_display}}.png" >
                    {{element.source_stream_table}}
                </a>
            </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Agency">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Tour </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.product ? element.product : element.agency}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Review">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" > Review </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.review_text.length <= 85 ? element.review_text : element.review_text | truncate:85:true:'...' }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="rating">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" > Rating </mat-header-cell>
                <mat-cell *matCellDef="let element" class="text-{{element.color}}">
                    {{element.stars.join(' ')}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sentiment_text">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" > Sentiment </mat-header-cell>
                <mat-cell *matCellDef="let element" class="text-{{element.color}}"> {{element.sentiment_text}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef class="text-primary">Actions</mat-header-cell>
            <mat-cell *matCellDef="let element" class="border-right-x2 border-5 border-{{element.color}}">
<!--                <div  class="btn custom-btn bg-primary text-white m-1">Respond</div>-->

                <div *ngIf="!element.can_respond" class="btn custom-btn bg-primary text-white m-1">
                  <a href="{{element.review_url}}" target="_blank" style="text-decoration: none; color: white;">
                    Respond
                  </a>
                </div>

                <div *ngIf="element.can_respond" class="btn custom-btn bg-primary text-white m-1" (click)="openReviewPopup(element)">Respond</div>
                <div class="btn custom-btn bg-success text-white m-1"
                     (click)="openReviewPopup(element)">
                  View
                </div>
            </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div *ngIf=" nextLink != '' " class="row d-flex justify-content-center m-3">
        <div class="btn btn-outline-primary rounded-pill m-1" (click)="getReviews(true)">Show 25 more</div>
    </div>
</div>
</div>
