import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ReviewService} from "../../../core/services/review.service";
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';


@Component({
  selector: 'app-review-popup',
  templateUrl: './review-popup.component.html',
  styleUrls: ['./review-popup.component.scss']
})
export class ReviewPopupComponent implements OnInit {
  places = []
  can_reply = false
  responseText: string = '';
  loading = false
  responded = false
  errorDuringResponse = false

  constructor(@Inject(MAT_DIALOG_DATA) public review: any,
              private reviewService: ReviewService,
              private dialog: MatDialog) {
    console.log(review.review)
    for (let place of review.review.places) {
      if (place.type === 'location' || place.type === 'restaurant') {
        this.places.push(place)
      }
    }
  }

  ngOnInit(): void {
  }

  reply() {
    this.loading = true
    this.errorDuringResponse = false
    this.responded = false
    this.reviewService.reply(this.review.review.id, this.responseText)
      .pipe(
        catchError(error => {
          console.error(error);
          this.loading = false;
          this.errorDuringResponse = true
          // Aggiungi qui la logica per mostrare un messaggio di errore all'utente
          // Puoi anche ritornare un observable di fallback utilizzando l'operatore RxJS 'of'
          return of(error);
        })
      )
      .subscribe(res => {
        console.log(res);
        this.loading = false;
        if (res.ok == true) {
          this.responded = true

          // Aggiungi qui la logica per gestire la risposta positiva
        } else {
          // Aggiungi qui la logica per gestire la risposta negativa
        }
      });
  }

  closePopup() {
    this.dialog.closeAll()
  }

}
