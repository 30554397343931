import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ClientService } from 'src/app/core/services/client.service';
import { Client } from 'src/app/models/client';
import {SnackBarService} from "../../../core/services/snack-bar.service";
import {SnackbarType} from "../snack-bar/snack-bar.component";

@Component({
  selector: 'app-client-delete-popup',
  templateUrl: './client-delete-popup.component.html',
  styleUrls: ['./client-delete-popup.component.scss']
})
export class ClientDeletePopupComponent implements OnInit {


  loading = false;
  responded = false;
  errorDuringResponse = false;

  constructor (
    public dialogRef: MatDialogRef<ClientDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { client: Client; },
    private clientService: ClientService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {

  }

  ngOnInit(): void {
  }

  deleteEmail(): void {

    this.loading = true;

    this.clientService.deleteClient(this.data.client.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
          this.snackBarService.openSnackBar("User Deleted", "User Deleted Successully", SnackbarType.Success);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
