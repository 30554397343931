import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TourService } from 'src/app/core/services/tour.service';
import { TourUrlStream } from 'src/app/enums/tour-url-stream';
import { Tour } from 'src/app/models/tour';
import { TourOperator } from 'src/app/models/tour-operator';
import { TourCreatePopupComponent } from '../tour-create-popup/tour-create-popup.component';
import { TourDeletePopupComponent } from '../tour-delete-popup/tour-delete-popup.component';
import { TourUploadPopupComponent } from '../tour-upload-popup/tour-upload-popup.component';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
// import { PaginationResponse } from 'src/app/models/pagination-response';
import { TourOperatorProductPopupComponent } from '../tour-operator-product-popup/tour-operator-product-popup.component';

@Component({
  selector: 'app-tour-list',
  templateUrl: './tour-list.component.html',
  styleUrls: ['./tour-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TourListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['tour_operator_name', 'category', 'email', 'website', 'country', 'state', 'city', 'rating', 'n_reviews', 'date_created', 'actions'];

  expandedElement: any;

  dataSource = new MatTableDataSource<Tour>();
  @ViewChild(MatTable) table: MatTable<Tour>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  tourOperatorList?: TourOperator[];

  TourUrlStream = TourUrlStream;
  results = []
  loading = true
  page: number = 1;
  Math: any;
  categoryData: any;
  filterGroup = new FormGroup({
    tour_operator: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    rating: new FormControl(''),
    date_created: new FormControl(''),
    category: new FormControl('')
  });

  // tourPayload?: PaginationResponse<Tour>;

  constructor (
    private tourService: TourService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.Math = Math;
    this.route.queryParams.subscribe({
      next: query => {
        if (query.page) {
          if (query.page > 0) {
            this.page = query.page;
          }
          else {
            this.router.navigate(["."], { relativeTo: this.route, queryParams: { page: 1 } });
            return;
          }
        }
        else {
          this.page = 1;
        }

        this.getTours();
      }
    });
  }

  ngOnInit() {
    console.log(this.filterGroup);

    this.filterGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500)
      )
      .subscribe({
        next: value => {
          this.page = 1;
          let params: Params = {
            page: this.page
          };

          for (const key in value) {
            if (value[key] !== null && value[key] !== "") {
              params[key] = value[key];
            }
          }

          this.router.navigate(["."], { relativeTo: this.route, queryParams: params });
        }
      });

      this.tourService.getCategory().subscribe((data: any) => {
        this.categoryData= data;
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getTours(): void {

    let data = this.filterGroup.value;
    console.log(data);

    if (this.filterGroup.value.date_created) {
      const date = new Date(this.filterGroup.value.date_created);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so we add 1 and pad with leading zero if needed.
      const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if needed.

      data.date_created = `${year}-${month}-${day}`;
    }

    this.tourService.getTours(data, { page: this.page }).subscribe({
      next: operators => {
        // this.tourPayload = operators;
        console.log(operators.results);

        this.results = operators.results
        this.loading = false
        this.dataSource.data = operators.results;
        this.paginator.length = operators.count;
        this.paginator.pageSize = 25;
        this.paginator.pageIndex = this.page - 1;

        this.table.renderRows();
      },
    });
  }

  changePage(event: PageEvent): void {
    const newPageIndex = event.pageIndex + 1;
    this.router.navigate(["."], { relativeTo: this.route, queryParams: { page: newPageIndex } });
  }

  openTourCreatePopup(tour?: Tour): void {
    this.dialog
      .open(TourCreatePopupComponent, {
        data: {
          tour,
        },
        width: "90%",
        height: "90%"
      })
      .afterClosed()
      .subscribe({
        next: (_) => {
          if (_ !== undefined) {
            this.getTours();
          }
        },
      });
  }

  openTourOperatorDeletePopup(tour: Tour): void {
    this.dialog
      .open(TourDeletePopupComponent, {
        data: {
          tour,
        },
      })
      .afterClosed()
      .subscribe({
        next: (_) => {
          if (_ !== undefined) {
            this.getTours();
          }
        },
      });
  }

  openTourUploadPopup(): void {
    this.dialog
      .open(TourUploadPopupComponent)
      .afterClosed()
      .subscribe({
        next: (_) => {
          if (_ !== undefined) {
            this.getTours();
          }
        },
      });
  }

  getTourOperatorName(tourOperatorId: number): string {
    if (this.tourOperatorList === undefined) {
      return '';
    }

    const tourOperator = this.tourOperatorList.find(tourOperator => tourOperator.id === tourOperatorId);

    if (tourOperator === undefined) {
      return '';
    }

    return tourOperator.name;
  }

  openTourOperatorProductPopup(tourOperatorId: number): void {
    this.dialog.open(TourOperatorProductPopupComponent, {
      data: {
        tourOperatorId,
        width: '600px',
        height: '400px',
      }
    });
  }
}
