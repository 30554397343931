import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportEmailService } from 'src/app/core/services/export-email.service';
import { EmailCreatePopupComponent } from '../email-create-popup/email-create-popup.component';
import { EmailDeletePopupComponent } from '../email-delete-popup/email-delete-popup.component';

@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ["name", "email", "actions"];

  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;


  constructor (
    private exportEmailService: ExportEmailService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getEmails();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getEmails(): void {
    this.exportEmailService.exportEmails().subscribe({
      next: emails => {
        this.dataSource.data = emails;
      }
    });
  }

  openEmailCreatePopup(): void {
    this.dialog.open(EmailCreatePopupComponent, {
      data: {
        'email': ''
      },
    })
      .afterClosed()
      .subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getEmails();
          }
        }
      });
  }

  openEmailDeletePopup(email: any): void {
    this.dialog.open(EmailDeletePopupComponent, {
      data: {
        email
      },
    })
      .afterClosed().subscribe({
        next: _ => {
          if (_ !== undefined) {
            this.getEmails();
          }
        }
      });
  }

}
