import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { PaginationResponse } from 'src/app/models/pagination-response';
import { Tour } from 'src/app/models/tour';
import { TourCreate } from 'src/app/models/tour-create';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';


const API_TOUR: string = environment.baseLink + '/v1/api/tours';
const API_OPRETER: string = environment.baseLink + '/v1/api/tour-operators';

const API_UPLOAD_TOURS: string = environment.baseLink + '/v1/api/upload-tours';
const API_UPLOAD_CATEGORYS: string = environment.baseLink + '/v1/api/category';


@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor (private http: HttpClient) { }

  getTours(filters: any, pagination: Pagination): Observable<PaginationResponse<Tour>> {

    let params = new HttpParams();
    params = params.set('page', pagination.page.toString());
    for (const key in filters) {
      if (filters[key] !== null && filters[key] !== "") {
        params = params.set(key, filters[key]);
      }
    }

    return this.http.get<PaginationResponse<Tour>>(`${API_TOUR}/`, { params });
  }

  createTour(tour: TourCreate): Observable<Tour> {
    return this.http.post<Tour>(`${API_TOUR}/`, tour);
  }

  updateTour(tour: Tour): Observable<Tour> {
    return this.http.put<Tour>(`${API_TOUR}/` + tour.id + '/', tour);
  }

  deleteTour(id: number): Observable<any> {
    return this.http.delete(`${API_TOUR}/` + id + '/');
  }

  uploadTour(file: File): Observable<any> {
    let data = new FormData();
    data.append('file', file);
    return this.http.post(`${API_UPLOAD_TOURS}/`, data);
  }

  getCategory() : Observable<any>{
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    return this.http.get(API_UPLOAD_CATEGORYS, {headers: httpHeaders})
    .pipe(
      map((res: any) => {
        return res;


        }),
        catchError(err => {
        // TODO better error handling
        return [null];
        })
    );
}


createOperator(name: any): Observable<any> {
  console.log('sfsdfdsfsfffs-------'+name);

  return this.http.post(`${API_OPRETER}/`, {name: name});
}
}
