import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserType } from 'src/app/enums/user-type';
import { User } from 'src/app/models/user';
import { SnackbarType } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'app-user-create-popup',
  templateUrl: './user-create-popup.component.html',
  styleUrls: ['./user-create-popup.component.scss']
})
export class UserCreatePopupComponent implements OnInit {

  UserType = UserType;

  loading = false;
  responded = false;
  errorDuringResponse = false;

  createUserForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl("", { validators: [Validators.required] }),
    email: new FormControl("", { validators: [Validators.required, Validators.email] }),
    password: new FormControl("", { validators: [Validators.required] }),
    user_type: new FormControl("", { validators: [Validators.required] })
  });


  constructor (
    public dialogRef: MatDialogRef<UserCreatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user?: User; },
    private userService: UserService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {
    if (data.user) {
      this.createUserForm.get("id")?.setValue(data.user.id);
      this.createUserForm.get("name")?.setValue(data.user.name);
      this.createUserForm.get("email")?.setValue(data.user.email);
      this.createUserForm.get("user_type")?.setValue(data.user.user_type);

      this.createUserForm.get("password")?.clearValidators();
      this.createUserForm.get("password")?.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
  }

  createUser(): void {

    this.createUserForm.markAllAsTouched();
    if (this.createUserForm.invalid) return;

    this.loading = true;

    if (this.data.user) {
      this.userService.updateUser(this.createUserForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: email => {
            this.dialogRef.close(email);
            this.snackBarService.openSnackBar("User Updated", "User Updated Successully", SnackbarType.Success);
          }
        });
    }
    else {
      this.userService.createUser(this.createUserForm.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: email => {
            this.dialogRef.close(email);
            this.snackBarService.openSnackBar("User Created", "User Created Successully", SnackbarType.Success);
          }
        });
    }
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
