import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ClientService } from 'src/app/core/services/client.service';
import { Email } from 'src/app/models/email';
import { ClientDeletePopupComponent } from '../client-delete-popup/client-delete-popup.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { SnackbarType } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'app-client-update-password-popup',
  templateUrl: './client-update-password-popup.component.html',
  styleUrls: ['./client-update-password-popup.component.scss']
})
export class ClientUpdatePasswordPopupComponent implements OnInit {


  loading = false;
  responded = false;
  errorDuringResponse = false;

  new_password = new FormControl("", { validators: [Validators.required] })

  constructor(
    public dialogRef: MatDialogRef<ClientDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { client: Client; },
    private clientService: ClientService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {

  }

  ngOnInit(): void {
  }

  updatePassword(): void {

    this.loading = true;
    this.new_password.markAllAsTouched();
    this.clientService.updateClientPassword(this.data.client.id, { new_password: this.new_password.value })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
          this.snackBarService.openSnackBar("Password Updated", "Password Updated Successully", SnackbarType.Success);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
