<div class="container-padding">
  <div class="col">

    <div class="row d-flex justify-content-between">
      <div class="col-12">
        <div class="card bg-white rounded">
          <div class="card-body col-12">
            <div class="card-title row d-flex justify-content-between">
              <div class="col d-flex">
                <h2>Comparative Graphics</h2>
              </div>
              <div class="col d-flex justify-content-end">
                <div [formGroup]="selectDateForm" class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-label>Select a prediod</mat-label>
                    <mat-select autocomplete="off" value="" (selectionChange)="selectChange($event)" formControlName="dateSelect">
                      <mat-option value="">
                      </mat-option>
                      <mat-option value="12">
                        Last Year
                      </mat-option>
                      <mat-option value="1">
                        Last Month
                      </mat-option>
                      <mat-option value="7">
                        Last Week
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-label>Date From</mat-label>
                    <input matInput (focus)="picker1.open()" [value]="selectedDateFromFilter " (dateChange)="dateFromChange($event, true)" [matDatepicker]="picker1" [readonly]="true" autocomplete="off">
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-label>Date To</mat-label>
                    <input matInput (focus)="picker2.open()" [value]="selectedDateToFilter" (dateChange)="dateToChange($event)" [matDatepicker]="picker2" [readonly]="true" autocomplete="off">
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="form-group ml-1">
                  <mat-form-field appearance="outline">
                    <mat-select class="agency-field" autocomplete="off" [(value)]="selectedAgenciesFilter" (selectionChange)="setAgencyFilter($event.value)">
                      <mat-option [value]="'all'" selected>
                        <img class="img-fluid mr-2" style="max-width:20px;" [src]="'../../../../assets/logos/all.png'">
                        Everything
                      </mat-option>
                      <mat-option *ngFor="let agency of agencies" [value]="agency.value">
                        <ng-container matOption>
                          <img class="img-fluid mr-2" style="max-width:20px;" src="../../../../assets/logos/{{agency.value}}.png">
                          {{agency.name}}
                        </ng-container>
                      </mat-option>
                    </mat-select>
                    <div class="selected-icon">
                      <img class="img-fluid" style="max-width:20px;" [src]="'../../../../assets/logos/' + selectedAgenciesFilter + '.png'" >
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-content-around">
              <div class="col">
                <div id="chartdivcomparativegreaphics" style="width: 100%; height: 500px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
