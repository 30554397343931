<div class="bg-backgound1 p-5 d-flex rounded">
    <div class="">
        <div class="text-center mb-10 mb-lg-20">
          <div class="bg-backgound2 rounded d-flex p-1">
            <a href="javascript:;" routerLink="/">
              <img alt="logo"logo src="../../../../../assets/logos/Tour_Review_Logo_Reversed_Registered.jpg" width="200px" height="112px" />
            </a>
          </div>
          <p class="text-button mt-2"></p>
          <p class="text-button mt-2">
<!--            Insert email and password-->
          </p>
        </div>

        <div class="bg-danger text-center rounded-pill" *ngIf="errorLogin">
          <p class="text-white font-weight-bold">
            Something wrong
          </p>
        </div>

        <form class="form" [formGroup]="loginForm" autocomplete="off" novalidate="novalidate">
            <div class="form-group">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email"
                  autocomplete="off" (input)="onSearchChange($event.target.value)"/>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password"
                  formControlName="password" autocomplete="off" (input)="onSearchChange($event.target.value)"/>
              </mat-form-field>
            </div>
<!--            <div class="form-group">-->
<!--              <mat-form-field>-->
<!--                <mat-label>OTP</mat-label>-->
<!--                <input matInput type="text"-->
<!--                  formControlName="otp" autocomplete="off" (input)="onSearchChange($event.target.value)"/>-->
<!--              </mat-form-field>-->
<!--            </div>-->
            <!--begin::Action-->
            <div class="form-group d-flex flex-wrap justify-content-between align-items-center mb-1">
              <button (click)="submit()" class="btn btn-button font-weight-bold w-100 px-2 py-2 my-3">Login
                <span *ngIf="loading" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              </button>
            </div>
            <!--end::Action-->
          </form>


    </div>
</div>
