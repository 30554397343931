<div>
  <p class="text-primary title">
    {{data.tourOperator? "Edit Tour Operator" : "Create Tour Operator"}}
  </p>

  <form [formGroup]="createTourOperatorForm">

    <div>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error
          *ngIf="createTourOperatorForm.get('name').invalid && createTourOperatorForm.get('name').errors['required']">This
          field is rquired</mat-error>
      </mat-form-field>
    </div>

  </form>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="createTourOperator()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn bg-primary text-white mt-2 mr-1">
      {{data.tourOperator? "Update" : "Create"}}
    </button>
    <button (click)="closePopup()" class="btn btn-outline-primary custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>