<div class="container-padding">
  <div class="col">

    <div class="row d-flex justify-content-between">
      <div class="col-12">
        <div class="card bg-white rounded">
          <div class="card-body col-12">

            <div class="card-title row d-flex justify-content-between">
              <div class="col d-flex">
                <h2>Tours</h2>
              </div>
              <div class="col d-flex justify-content-end">
                <div (click)="openTourUploadPopup()"
                  class="btn rounded-pill text-white bg-primary h-75 d-flex mr-1 pt-2 tb-2 pl-3 pr-3">
                  <mat-icon matTooltip="edit">add</mat-icon>
                  <p class="mt  ml-2">Upload Tour</p>
                </div>
                <div (click)="openTourCreatePopup()"
                  class="btn rounded-pill text-white bg-primary h-75 d-flex mr-1 pt-2 tb-2 pl-3 pr-3">
                  <mat-icon matTooltip="edit">add</mat-icon>
                  <p class="mt  ml-2">Create a Tour</p>
                </div>
              </div>
            </div>

            <div class="row" [formGroup]="filterGroup">

              <div class="col-3">
                <mat-form-field class="w-100">
                  <mat-label>Tour Operator</mat-label>
                  <input matInput type="text" formControlName="tour_operator" />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field class="w-100">
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="category">
                    <mat-option [value]="null">All</mat-option>
                    <mat-option  *ngFor="let item of categoryData;" [value]="item.name">{{item.name}}</mat-option>
                  </mat-select>
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field class="w-100">
                  <mat-label>Country</mat-label>
                  <input matInput type="text" formControlName="country" />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-3">
                <mat-form-field class="w-100">
                  <mat-label>State</mat-label>
                  <input matInput type="text" formControlName="state" />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-4">
                <mat-form-field class="w-100">
                  <mat-label>City</mat-label>
                  <input matInput type="text" formControlName="city" />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>

              <div class="col-4">
                <mat-form-field class="w-100">
                  <mat-label>Rating</mat-label>
                  <mat-select formControlName="rating">
                    <mat-option [value]="null">All</mat-option>
                    <mat-option [value]="1">1</mat-option>
                    <mat-option [value]="2">2</mat-option>
                    <mat-option [value]="3">3</mat-option>
                    <mat-option [value]="4">4</mat-option>
                    <mat-option [value]="5">5</mat-option>
                  </mat-select>
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>



              <div class="col-4">
                <mat-form-field class="w-100">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="date_created">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <button *ngIf="filterGroup.value.date_created" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="resetDateCreated()">
                    <mat-icon>{{'clear'}}</mat-icon>
                  </button>
                </mat-form-field>
              </div>



            </div>

            <div>

              <table class="mat-table" mat-table #table [dataSource]="dataSource" matSort #sort="matSort"
                matSortActive="Date" matSortDirection="asc" multiTemplateDataRows>

                <ng-container matColumnDef="tour_operator_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Tour Operator
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.tour_operator_name}}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Category
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.category == "0" ? "-" : element.category}}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Email </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{ element.email && element.email.length > 20 ? (element.email | slice:0:20) + '...' :
                      element.email }}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="n_reviews">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Number of
                    Reviews </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{ element.n_reviews }}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="website">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Website </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{ element.website && element.website.length > 20 ? (element.website | slice:0:20) + '...' :
                      element.website }}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="country">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Country </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.country}}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="state">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> State </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.state}}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="city">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> City </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.city}}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="rating">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Rating </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.rating | number:'1.3-3'}}
<!--                      <ng-container *ngFor="let i of [].constructor(Math.round(element.rating)); let index = index">-->
<!--                        ★-->
<!--                      </ng-container>-->
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="expanded-element-container"
                      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                      <div class="expanded-content">
                        <ul class="tour_urls max-width-80">
                          <ng-container *ngFor="let item of element.tour_urls">
                            <li>
                              <b>{{ TourUrlStream[item.stream] }}</b>: <a [href]="item.url" target="_blank">{{ item.url
                                }}</a>
                            </li>
                          </ng-container>
                        </ul>

                      </div>

                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="date_created">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> Date Created
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="chip-wrapper">
                      {{element.date_created | date}}
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-primary"> Actions
                  </th>
                  <td mat-cell *matCellDef="let element" class="action-cell">
                    <div>
                      <div class="btn custom-btn bg-primary text-white m-1"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        Tours
                      </div>
                      <div class="btn custom-btn bg-success text-white m-1"
                        (click)="openTourOperatorProductPopup(element.tour_operator)">
                        View
                      </div>
                    </div>
                    <div>
                      <div class="btn custom-btn bg-primary text-white m-1" (click)="openTourCreatePopup(element)">
                        Edit
                      </div>
                      <div class="btn custom-btn bg-danger text-white m-1"
                        (click)="openTourOperatorDeletePopup(element)">
                        Delete
                      </div>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expnadable-tr"></tr>
              </table>

              <div *ngIf="results.length === 0 && loading === false">
                <p>No Results</p>
              </div>

              <div>
                <mat-paginator (page)="changePage($event)" aria-label="Select page">
                </mat-paginator>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
