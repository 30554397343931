import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ClientService } from 'src/app/core/services/client.service';
import { UserRole } from 'src/app/enums/user-role';
import { UserType } from 'src/app/enums/user-type';
import { Client } from 'src/app/models/client';
import {SnackbarType} from "../snack-bar/snack-bar.component";
import {SnackBarService} from "../../../core/services/snack-bar.service";
import { TourService } from 'src/app/core/services/tour.service';

@Component({
  selector: 'app-new-oprater-create-popup',
  templateUrl: './new-oprater-create-popup.component.html',
  styleUrls: ['./new-oprater-create-popup.component.scss']
})
export class NewOpraterCreateComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;
  new_operator = new FormControl('', [Validators.required]);;

  UserRole = UserRole;
  UserType = UserType;

  // createNewOperatorForm = new FormGroup({
  //   id: new FormControl(null),
  //   new_operator: new FormControl("", { validators: [Validators.required] }),
  // });


  constructor(
    public dialogRef: MatDialogRef<NewOpraterCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {  },
    private tourService: TourService,
  ) {
  }

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  submit(): void {
    console.log(this.new_operator.value);

    if (this.new_operator.valid) {

      console.log('Input Value:', this.new_operator.value);
      this.tourService
        .createOperator(this.new_operator.value)
        .pipe(
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe({
          next: tourOperator => {
            this.dialogRef.close();
          },
        });
    } else {

    }
  }
}
