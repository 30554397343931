import { Component, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ReportService } from "../../../core/services/report.service";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-summary-and-evolution',
  templateUrl: './summary-and-evolution.component.html',
  styleUrls: ['./summary-and-evolution.component.scss']
})

export class SummaryAndEvolutionComponent {
  private chart: am4charts.XYChart;

  agencies = [
    {
      "name": "Viator",
      "value": "viator"
    },
    {
      "name": "Airbnb",
      "value": "airbnb"
    },
    {
      "name": "Tripadvisor",
      "value": "tripadvisor"
    },
    {
      "name": "Expedia",
      "value": "expedia"
    },
    {
      "name": "Klook",
      "value": "klook"
    },
    {
      "name": "Civitatis",
      "value": "civitatis"
    },
    {
      "name": "Get Your Guide",
      "value": "getyourguide"
    },
    {
      "name": "Facebook",
      "value": "facebook"
    },
    {
      "name": "Google",
      "value": "google"
    },
    {
      "name": "Instagram",
      "value": "instagram"
    },
    {
      "name": "Linkedin",
      "value": "linkedin"
    }
  ];

  selectedDateFromFilter: Date;
  selectedDateToFilter: Date;
  selectedAgenciesFilter: string = 'all';
  selectDateForm: FormGroup;

  constructor (
    @Inject(PLATFORM_ID) private platformId,
    private zone: NgZone,
    private reportService: ReportService,
    private fb: FormBuilder
  ) {
    this.reportService.filterDateChangeEvent.subscribe(event => {
      this.selectedDateFromFilter = event.dateFrom;
      this.selectedDateToFilter = event.dateTo;
      this.selectDateForm.controls['dateSelect'].setValue(event.selectValue);
      this.getFilterData();
    });

    this.reportService.agencyChangeEvent.subscribe(event => {
      this.selectedAgenciesFilter = event.agency;
      this.getFilterData();
    });

    this.selectDateForm = fb.group({
      'dateSelect': ['1'],
    });
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngAfterViewInit() {

    let today = new Date();
    this.selectedDateToFilter = new Date();
    today.setMonth(today.getMonth() - 1);
    this.selectedDateFromFilter = today;

    // Chart code goes in here
    this.reportService.summaryEvolution('all').subscribe((data: any) => {
      this.evolutionChart(data);
    });
    this.reportService.summaryEvolutionTotal('all').subscribe((data: any) => {
      this.summerizeChart(data);
    });
  }

  summerizeChart(data) {
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chartMin = 0;
    let chartMax = 100;

    /**
     Grading Lookup
     */
    function lookUpGrade(lookupScore, grades) {
      // Only change code below this line
      for (var i = 0; i < grades.length; i++) {
        if (
          grades[i].lowScore < lookupScore &&
          grades[i].highScore >= lookupScore
        ) {
          return grades[i];
        }
      }
      return null;
    }

    // create chart
    let chart = am4core.create("chartdivsummerize", am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(80);
    chart.resizable = true;

    chart.startAngle = -220;
    chart.endAngle = 40;

    /**
     * Normal axis
     */
    // @ts-ignore
    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    // @ts-ignore
    axis.min = chartMin;
    // @ts-ignore
    axis.max = chartMax;
    // @ts-ignore
    axis.strictMinMax = true;
    // @ts-ignore
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 0.1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.strokeWidth = 0.5;
    axis.renderer.ticks.template.length = 5;
    axis.renderer.grid.template.disabled = true;
    // @ts-ignore
    axis.renderer.labels.template.radius = am4core.percent(15);
    axis.renderer.labels.template.fontSize = "0.9em";

    /**
     * Axis for ranges
     */
    // @ts-ignore
    let axis2 = chart.xAxes.push(new am4charts.ValueAxis());
    // @ts-ignore
    axis2.min = chartMin;
    // @ts-ignore
    axis2.max = chartMax;
    // @ts-ignore
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = false;
    axis2.renderer.grid.template.opacity = 0.5;
    // @ts-ignore
    axis2.renderer.labels.template.bent = true;
    axis2.renderer.labels.template.fill = am4core.color("#000");
    axis2.renderer.labels.template.fontWeight = "bold";
    axis2.renderer.labels.template.fillOpacity = 0.3;



    /**
     Ranges
     */
    for (let grading of data.gradingData) {
      let range = axis2.axisRanges.create();
      range.axisFill.fill = am4core.color(grading.color);
      range.axisFill.fillOpacity = 0.8;
      range.axisFill.zIndex = -1;
      // @ts-ignore
      range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin;
      // @ts-ignore
      range.endValue = grading.highScore < chartMax ? grading.highScore : chartMax;
      range.grid.strokeOpacity = 0;
      // @ts-ignore
      range.stroke = am4core.color(grading.color).lighten(-0.1);
      range.label.inside = true;
      range.label.text = grading.sentiment.toUpperCase();
      range.label.inside = true;
      range.label.location = 0.5;
      range.label.inside = true;
      // @ts-ignore
      range.label.radius = am4core.percent(10);
      range.label.paddingBottom = -5; // ~half font size
      range.label.fontSize = "0.9em";
    }

    let matchingGrade = lookUpGrade(data.score, data.gradingData);

    /**
     * Label 1
     */

    let label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = "3em";
    label.x = am4core.percent(50);
    label.paddingBottom = 15;
    label.horizontalCenter = "middle";
    // label.text = data.score.toFixed() + '%';
    // label.text = "{score}";
    label.fill = am4core.color(matchingGrade.color);

    /**
     * Label 2
     */

    let label2 = chart.radarContainer.createChild(am4core.Label);
    label2.isMeasured = false;
    label2.fontSize = "2em";
    label2.horizontalCenter = "middle";
    label2.verticalCenter = "bottom";
    label2.text = matchingGrade.sentiment.toUpperCase();
    label2.fill = am4core.color(matchingGrade.color);


    /**
     * Hand
     */

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(55);
    hand.startWidth = 8;
    hand.pin.disabled = true;
    hand.value = data.score;
    hand.fill = am4core.color("#444");
    hand.stroke = am4core.color("#000");

    hand.events.on("positionchanged", function () {
      // @ts-ignore
      label.text = axis2.positionToValue(hand.currentPosition).toFixed(1) + '%';
      // @ts-ignore
      let value2 = axis.positionToValue(hand.currentPosition);
      // @ts-ignore
      let matchingGrade = lookUpGrade(axis.positionToValue(hand.currentPosition), data.gradingData);
      label2.text = matchingGrade.sentiment.toUpperCase();
      label2.fill = am4core.color(matchingGrade.color);
      label2.stroke = am4core.color(matchingGrade.color);
      label.fill = am4core.color(matchingGrade.color);
    });
  }

  evolutionChart(data) {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("chartdivevolution", am4charts.XYChart);
      chart.paddingRight = 20;

      // Add data
      chart.data = data;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      // @ts-ignore
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.minGridDistance = 100; // Aumenta la distanza minima della griglia
      categoryAxis.renderer.grid.template.location = 0.5;
      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0.5;

      // Create DateFormatter instance and set desired format
      let dateFormatter = new am4core.DateFormatter();
      dateFormatter.dateFormat = "d MMMM yyyy";
      categoryAxis.renderer.labels.template.adapter.add("textOutput", function (text, target) {
        return dateFormatter.format(new Date(text));
      });

      // Create value axis
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.baseValue = 0;

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      // @ts-ignore
      series.dataFields.valueY = "value";
      // @ts-ignore
      series.dataFields.categoryX = "date";
      series.strokeWidth = 2;
      series.tensionX = 0.77;

      // bullet is added because we add tooltip to a bullet for it to change color
      let bullet = series.bullets.push(new am4charts.Bullet());
      bullet.tooltipText = "{valueY}";

      bullet.adapter.add("fill", function (fill, target) {
        // @ts-ignore
        if (target.dataItem.valueY < 0) {
          return am4core.color("#FF0000");
        }
        return fill;
      });

      let range = valueAxis.createSeriesRange(series);
      range.value = 0;
      range.endValue = -10000000;
      range.contents.stroke = am4core.color("#FF0000");
      range.contents.fill = range.contents.stroke;
      range.contents.strokeOpacity = 0.7;
      range.contents.fillOpacity = 0.1;

      let range2 = valueAxis.createSeriesRange(series);
      range2.value = 0.1;
      range2.endValue = 10000000;
      range2.contents.stroke = am4core.color("#28a745");
      range2.contents.fill = am4core.color("#28a745");
      range2.contents.strokeOpacity = 0.7;
      range2.contents.fillOpacity = 0.1;

      // Add scrollbar
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      // chart.scrollbarX = scrollbarX;

      chart.cursor = new am4charts.XYCursor();
      this.chart = chart;
    });
  }


  selectChange($event) {
    if ($event.value == "")
      return;

    let today = new Date();

    if ($event.value == 7) {
      today.setDate(today.getDate() - 7);
    } else {
      today.setMonth(today.getMonth() - $event.value);
    }

    $event.value = today;
    this.dateFromChange($event, false);
  }


  dateFromChange($event, resetSelect) {
    this.selectedDateFromFilter = $event.value;

    if (resetSelect) {
      this.selectDateForm.controls['dateSelect'].setValue('');
    }

    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter,
      selectValue: this.selectDateForm.controls['dateSelect'].value
    });
    this.getFilterData();
  }

  dateToChange($event) {
    this.selectedDateToFilter = $event.value;
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter
    });
    this.getFilterData();
  }

  setAgencyFilter(value): void {
    this.selectedAgenciesFilter = value;
    this.reportService.agencyChangeEvent.emit({
      agency: this.selectedAgenciesFilter
    });
    this.getFilterData();
  }

  getFilterData(): void {
    this.reportService.summaryEvolution(this.selectedAgenciesFilter, this.selectedDateFromFilter.toISOString().split('T')[0], this.selectedDateToFilter.toISOString().split('T')[0]).subscribe((data: any) => {
      this.evolutionChart(data);
    });
    this.reportService.summaryEvolutionTotal(this.selectedAgenciesFilter, this.selectedDateFromFilter.toISOString().split('T')[0], this.selectedDateToFilter.toISOString().split('T')[0]).subscribe((data: any) => {
      this.summerizeChart(data);
    });
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
