import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LoggedInUser } from 'src/app/core/models/logged-in-user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserRole } from 'src/app/enums/user-role';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  UserRole = UserRole;
  user: LoggedInUser;
  showFiller = false;

  menuList: {
    icon: string,
    url: string,
    role?: UserRole;
  }[] = [
      // {
      //   icon: "multiline_chart",
      //   url: "/report",
      //   role: UserRole.Client
      // },
      // {
      //   icon: "person",
      //   url: "/profile",
      //   role: UserRole.Client
      // },
      // {
      //   icon: "schedule",
      //   url: "/schedule",
      //   role: UserRole.Client
      // },
      {
        icon: "supervised_user_circle",
        url: "/client",
        role: UserRole.Admin
      },
      // {
      //   icon: "supervised_user_circle",
      //   url: "/user",
      //   role: UserRole.Client
      // },
      {
        icon: "assignment",
        url: "/tour",
        role: UserRole.Client
      },
      {
        icon: "business",
        url: "/tour-operator",
        role: UserRole.Client
      },
      {
        icon: "business",
        url: "/tour-operator-score",
        role: UserRole.Admin
      },
    ];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = Global.user;
  }


  logout(): void {
    this.authService.logout();
  }
}
