import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Email } from 'src/app/models/email';
import { EmailCreate } from 'src/app/models/email-create';
import { environment } from 'src/environments/environment';


const API_EXPORTS_EMAILS: string = environment.baseLink + '/v1/api/schedule-email/';

@Injectable({
  providedIn: 'root'
})
export class ExportEmailService {

  constructor (private http: HttpClient) {

  }

  exportEmails(): Observable<Email[]> {
    return this.http.get<Email[]>(API_EXPORTS_EMAILS);
  }

  createExportEmail(email: EmailCreate): Observable<Email> {
    return this.http.post<Email>(API_EXPORTS_EMAILS, email);
  }

  deleteEmail(id: number): Observable<any> {
    return this.http.delete(`${API_EXPORTS_EMAILS}${id}/`);
  }

}
