import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ExportEmailService } from 'src/app/core/services/export-email.service';
import { Email } from 'src/app/models/email';

@Component({
  selector: 'app-email-delete-popup',
  templateUrl: './email-delete-popup.component.html',
  styleUrls: ['./email-delete-popup.component.scss']
})
export class EmailDeletePopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  constructor (
    public dialogRef: MatDialogRef<EmailDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email: Email; },
    private emailService: ExportEmailService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
  }

  deleteEmail(): void {

    this.loading = true;

    this.emailService.deleteEmail(this.data.email.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: response => {
          this.dialogRef.close(response);
        }
      });
  }

  closePopup() {
    this.dialog.closeAll();
  }

}
