<div>
  <p class="text-primary title">Upload Tour</p>

  <div class="container">

    <div class="dropzone">
      <input type="file" id="fileDropRef" (change)="onFileUpload($event.target.files)" />
      <img src="" />
      <h3>Drag and drop file here</h3>
      <h3>or</h3>
      <label for="fileDropRef" class="fileDropRef">Browser for file</label>
    </div>

    <ng-container *ngIf="file">
      <div>
        <p class="text-primary">File Name: {{file.name}}</p>
        <p class="text-primary">File Size: {{fileSize}} MB</p>
      </div>
    </ng-container>

  </div>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="uploadFile()" [disabled]="!file" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn text-white mt-2 mr-1">
      Upload File
    </button>
    <button (click)="closePopup()" class="btn custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>
