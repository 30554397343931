import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { InterceptService } from 'src/app/core/services/intercept.service';
import { TruncatePipe } from "../../core/services/truncate.pipe";
import { CountersComponent } from './counters/counters.component';
import { LastReviwesComponent } from './last-reviwes/last-reviwes.component';
import { LiveReviewListComponent } from './live-review-list/live-review-list.component';
import { NpsDowloadReportComponent } from './nps-dowload-report/nps-dowload-report.component';
import { ReviewPopupComponent } from './review-popup/review-popup.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ToolbarAlertComponent } from './toolbar-alert/toolbar-alert.component';
// import {SummaryAndEvolutionComponent} from "./summary-and-evolution/summary-and-evolution.component";
import { DateAdapter } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from "@angular/material/radio";
import { EnumToListPipeModule } from 'src/app/pipes/enum-to-list.pipe.module';
import { CustomDateAdapter } from "../../core/services/CustomDateAdapter";
import { ClientCreateComponent } from './client-create-popup/client-create-popup.component';
import { NewOpraterCreateComponent } from './new-oprater-create-popup/new-oprater-create-popup.component';

import { ClientDeletePopupComponent } from './client-delete-popup/client-delete-popup.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientUpdatePasswordPopupComponent } from './client-update-password-popup/client-update-password-popup.component';
import { ComparativeGraphicsComponent } from './comparative-graphics/comparative-graphics.component';
import { EmailCreatePopupComponent } from './email-create-popup/email-create-popup.component';
import { EmailDeletePopupComponent } from './email-delete-popup/email-delete-popup.component';
import { EmailListComponent } from './email-list/email-list.component';
import { GeneralReportComponent } from "./general-report/general-report.component";
import { KeywordsTableComponent } from './keywords-table/keywords-table.component';
import { LocationOfReviewsComponent } from './location-of-reviews/location-of-reviews.component';
import { ReputationManagementComponent } from './reputation-management/reputation-management.component';
import { ScheduleCreatePopupComponent } from './schedule-create-popup/schedule-create-popup.component';
import { ScheduleDeletePopupComponent } from './schedule-delete-popup/schedule-delete-popup.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { SummaryAndEvolutionComponent } from './summary-and-evolution/summary-and-evolution.component';
import { TourCreatePopupComponent } from './tour-create-popup/tour-create-popup.component';
import { TourDeletePopupComponent } from './tour-delete-popup/tour-delete-popup.component';
import { TourListComponent } from './tour-list/tour-list.component';
import { TourOperatorListComponent } from './tour-operator-list/tour-operator-list.component';
import { TourOperatorsCreatePopupComponent } from './tour-operators-create-popup/tour-operators-create-popup.component';
import { TourOperatorsDeletePopupComponent } from './tour-operators-delete-popup/tour-operators-delete-popup.component';
import { UserCreatePopupComponent } from './user-create-popup/user-create-popup.component';
import { UserDeletePopupComponent } from './user-delete-popup/user-delete-popup.component';
import { UserListComponent } from './user-list/user-list.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { SnackBarModule } from './snack-bar/snack-bar.module';
import { TourUploadPopupComponent } from './tour-upload-popup/tour-upload-popup.component';
import { TourOperatorProductPopupComponent } from './tour-operator-product-popup/tour-operator-product-popup.component';

@NgModule({
  declarations: [
    SideNavComponent,
    LiveReviewListComponent,
    LastReviwesComponent,
    TruncatePipe,
    CountersComponent,
    ReviewPopupComponent,
    ToolbarAlertComponent,
    NpsDowloadReportComponent,
    SummaryAndEvolutionComponent,
    LocationOfReviewsComponent,
    ComparativeGraphicsComponent,
    ReputationManagementComponent,
    KeywordsTableComponent,
    GeneralReportComponent,

    ScheduleListComponent,
    ScheduleCreatePopupComponent,
    ScheduleDeletePopupComponent,

    EmailListComponent,
    EmailCreatePopupComponent,
    EmailDeletePopupComponent,

    ClientListComponent,
    ClientCreateComponent,
    NewOpraterCreateComponent,
    ClientDeletePopupComponent,
    ClientUpdatePasswordPopupComponent,

    UserListComponent,
    UserCreatePopupComponent,
    UserDeletePopupComponent,

    TourOperatorListComponent,
    TourOperatorsCreatePopupComponent,
    TourOperatorsDeletePopupComponent,
    TourOperatorProductPopupComponent,

    TourListComponent,
    TourCreatePopupComponent,
    TourDeletePopupComponent,
    TourUploadPopupComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatSortModule,
    RouterModule,
    MatDialogModule,
    MatChipsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatRadioModule,
    MatPaginatorModule,

    EnumToListPipeModule,

    SnackBarModule
  ],
  exports: [
    SideNavComponent,
    LiveReviewListComponent,
    LastReviwesComponent,
    CountersComponent,
    NpsDowloadReportComponent,
    SummaryAndEvolutionComponent,
    LocationOfReviewsComponent,
    ComparativeGraphicsComponent,
    ReputationManagementComponent,
    KeywordsTableComponent,
    GeneralReportComponent,

    ScheduleListComponent,
    ScheduleCreatePopupComponent,
    ScheduleDeletePopupComponent,

    EmailListComponent,
    EmailCreatePopupComponent,
    EmailDeletePopupComponent,

    ClientListComponent,
    ClientCreateComponent,
    NewOpraterCreateComponent,
    ClientDeletePopupComponent,
    ClientUpdatePasswordPopupComponent,

    UserListComponent,
    UserCreatePopupComponent,
    UserDeletePopupComponent,

    TourOperatorListComponent,
    TourOperatorsCreatePopupComponent,
    TourOperatorsDeletePopupComponent,
    TourOperatorProductPopupComponent,

    TourListComponent,
    TourCreatePopupComponent,
    TourDeletePopupComponent,
    TourUploadPopupComponent,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
})
export class PartialsModule { }
