<div>
  <p class="text-primary title">Review</p>
  <div class="text-{{review.review.color}}">
    {{review.review.stars.join(' ')}}
    <mat-chip class="text-{{review.review.color}} chip-bg">
      {{review.review.sentiment_display}}
      <mat-icon class="icon-display" fontSet="material-icons-outlined" *ngIf="review.review.sentiment === 1">sentiment_dissatisfied</mat-icon>
      <mat-icon class="icon-display" fontSet="material-icons-outlined" *ngIf="review.review.sentiment === 2">sentiment_neutral</mat-icon>
      <mat-icon class="icon-display" fontSet="material-icons-outlined" *ngIf="review.review.sentiment === 3">sentiment_satisfied</mat-icon>
    </mat-chip>
  </div>
  <div class="chip-wrapper">
    <mat-chip-list>
      <mat-chip *ngIf="review.review.responded" class="chip chip-bg-success">
        Responded
        <mat-icon class="icon-display" fontSet="material-icons-outlined">done</mat-icon>
      </mat-chip>
      <mat-chip class="chip" *ngFor="let place of places">
        {{place.name}}
        <mat-icon class="icon-display" fontSet="material-icons-outlined" *ngIf="place.type === 'location'">location_on</mat-icon>
        <mat-icon class="icon-display" fontSet="material-icons-outlined" *ngIf="place.type === 'restaurant'">restaurant</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <p>
    {{ review.review.review_text }}
  </p>
  <textarea *ngIf="review.review.can_respond" [(ngModel)]="responseText" type="textarea" class="textarea" rows="5" placeholder="Write your response"></textarea>

  <div class="card bg-danger" *ngIf="errorDuringResponse">
    <p class="text-center text-white align-middle1 mt-3">Error during response!</p>
  </div>
  <div class="card bg-success" *ngIf="responded">
    <p class="text-center text-white align-middle1 mt-3">Responded succesfully!</p>
  </div>

  <button *ngIf="review.review.can_respond" (click)="reply()" [disabled]="responseText.length === 0 || loading"
          [ngClass]="{'loading pr-5': loading}"
          class="btn custom-btn bg-primary text-white mt-2">
    Respond
  </button>

  <div *ngIf="!review.review.can_respond" class="btn custom-btn bg-primary text-white mt-2">
    <a href="{{review.review.review_url}}" target="_blank" style="text-decoration: none; color: white;">
      Respond
    </a>
  </div>

  <button (click)="closePopup()"
          class="btn btn-outline-primary custom-btn-outline text-primary mt-2 mr-1">
    Close
  </button>

</div>
