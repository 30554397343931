import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { dataPositiveMock, dataNegativeMock } from './mock_data';
import { ReportService } from 'src/app/core/services/report.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-keywords-table',
  templateUrl: './keywords-table.component.html',
  styleUrls: ['./keywords-table.component.scss']
})
export class KeywordsTableComponent implements OnInit {

  @ViewChild('sort1') sort1: MatSort;
  @ViewChild('sort2') sort2: MatSort;

  agencies = [
    {
      "name": "Viator",
      "value": "viator"
    },
    {
      "name": "Airbnb",
      "value": "airbnb"
    },
    {
      "name": "Tripadvisor",
      "value": "tripadvisor"
    },
    {
      "name": "Expedia",
      "value": "expedia"
    },
    {
      "name": "Klook",
      "value": "klook"
    },
    {
      "name": "Civitatis",
      "value": "civitatis"
    },
    {
      "name": "Get Your Guide",
      "value": "getyourguide"
    },
    {
      "name": "Facebook",
      "value": "facebook"
    },
    {
      "name": "Google",
      "value": "google"
    },
    {
      "name": "Instagram",
      "value": "instagram"
    },
    {
      "name": "Linkedin",
      "value": "linkedin"
    }
  ]
  selectedDateFromFilter: Date;
  selectedDateToFilter: Date;
  selectedAgenciesFilter: string = 'all';
  dataPositive: any[] = [];
  dataNegative: any[] = [];
  displayedColumns: string[] = ["word", "number_of_repetitions", "impact", "number_of_repetitions_variation", ]
  dataNegativeSource = new MatTableDataSource();
  dataPositiveSource = new MatTableDataSource();
  selectDateForm: FormGroup;

  constructor(
    private reportService: ReportService,
    private fb: FormBuilder
  ) {
    this.reportService.filterDateChangeEvent.subscribe(event => {
      this.selectedDateFromFilter = event.dateFrom
      this.selectedDateToFilter = event.dateTo
      this.selectDateForm.controls['dateSelect'].setValue(event.selectValue);
      this.getFilterData();
    })

    this.reportService.agencyChangeEvent.subscribe(event => {
      this.selectedAgenciesFilter = event.agency;
      this.getFilterData();
    })

    this.selectDateForm = fb.group({
      'dateSelect': ['1'],
    });
  }

  ngOnInit(): void {
    this.dataPositiveSource.data = dataPositiveMock;
    this.dataNegativeSource.data = dataNegativeMock;
    this.reportService.keywordsPositiveData('all').subscribe((data: any) => {
      this.dataPositive = data.positive;
      this.dataPositiveSource.data = data.positive;
      this.dataNegative = data.negative;
      this.dataNegativeSource.data = data.negative;
    });
  }

  ngAfterViewInit() {

    let today = new Date();
    this.selectedDateToFilter = new Date();
    today.setMonth(today.getMonth() - 1);
    this.selectedDateFromFilter = today;

    this.dataNegativeSource.sort = this.sort1;
    this.dataPositiveSource.sort = this.sort2;
  }

  selectChange($event){
    if($event.value == "")
      return;

    let today = new Date();

    if ( $event.value == 7 ){
      today.setDate(today.getDate() - 7 );
    } else {
      today.setMonth(today.getMonth() - $event.value);
    }

    $event.value = today;
    this.dateFromChange($event, false);
  }

  dateFromChange($event, resetSelect){
    this.selectedDateFromFilter = $event.value;

    if(resetSelect){
      this.selectDateForm.controls['dateSelect'].setValue('');
    }

    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter,
      selectValue: this.selectDateForm.controls['dateSelect'].value
    })
    this.getFilterData();
  }

  dateToChange($event){
    this.selectedDateToFilter = $event.value;
    this.reportService.filterDateChangeEvent.emit({
      dateFrom: this.selectedDateFromFilter,
      dateTo: this.selectedDateToFilter
    })
    this.getFilterData();
  }

  setAgencyFilter(value): void {
    this.selectedAgenciesFilter = value;
    this.reportService.agencyChangeEvent.emit({
      agency: this.selectedAgenciesFilter
    })
    this.getFilterData();
  }

  getFilterData(): void{

    this.reportService.keywordsPositiveData(this.selectedAgenciesFilter, this.selectedDateFromFilter.toISOString().split('T')[0], this.selectedDateToFilter.toISOString().split('T')[0]).subscribe((data: any) => {
      this.dataPositive = data.positive;
      this.dataPositiveSource.data = data.positive;
      this.dataNegative = data.negative;
      this.dataNegativeSource.data = data.negative;
    });
  }

}
