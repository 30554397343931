import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { City } from 'src/app/models/city';
import { Country } from 'src/app/models/country';
import { State } from 'src/app/models/state';
import { environment } from 'src/environments/environment';

const API_COUNTRIES: string = environment
  .baseLink + '/v1/api/countries/';

const API_STATES: string = environment
  .baseLink + '/v1/api/states/';

const API_CITIES: string = environment
  .baseLink + '/v1/api/cities/';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor (private http: HttpClient) { }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(API_COUNTRIES);
  }

  getStates(countryId: number): Observable<State[]> {
    let params = new HttpParams();
    params = params.set("country_id", countryId.toString());
    return this.http.get<State[]>(API_STATES, { params });
  }

  getCities(stateId: number): Observable<City[]> {
    let params = new HttpParams();
    params = params.set("state_id", stateId.toString());
    return this.http.get<City[]>(API_CITIES, { params });

  }

}
