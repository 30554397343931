import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { TourOperatorService } from 'src/app/core/services/tour-operator.service';
import { TourService } from 'src/app/core/services/tour.service';
import { TourUrlStream } from 'src/app/enums/tour-url-stream';
import { Tour } from 'src/app/models/tour';
import { TourOperator } from 'src/app/models/tour-operator';

@Component({
  selector: 'app-tour-operator-product-popup',
  templateUrl: './tour-operator-product-popup.component.html',
  styleUrls: ['./tour-operator-product-popup.component.scss']
})
export class TourOperatorProductPopupComponent implements OnInit {

  loading = false;
  responded = false;
  errorDuringResponse = false;

  displayedColumns: string[] = ['email', 'website', 'country', 'state', 'city', 'rating', 'NReviews', 'dateCreated'];
  tourOperatorList?: TourOperator[];

  TourUrlStream = TourUrlStream;
  page: number = 1;
  Math: any;
  expandedElement: any;

  dataSource = new MatTableDataSource<Tour>();
  @ViewChild(MatTable) table: MatTable<Tour>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor (
    public dialogRef: MatDialogRef<TourOperatorProductPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tourOperatorId: number; },
    private tourOperatorService: TourService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) {
    this.Math = Math;


  }

  ngOnInit(): void {
    this.getTourByTourOperatorId();
  }

  getTourByTourOperatorId(): void {
    this.tourOperatorService.getTours({ tour_operator_id: this.data.tourOperatorId }, { page: 1 })
      .subscribe({
        next: operators => {
          this.dataSource.data = operators.results;
          this.paginator.length = operators.count;
          this.paginator.pageSize = 25;
          this.paginator.pageIndex = this.page - 1;

          this.table.renderRows();
        }
      });
  }

  changePage(event: PageEvent): void {
    const newPageIndex = event.pageIndex + 1;
    this.page = newPageIndex;
    this.getTourByTourOperatorId();
  }

  closePopup() {
    this.dialog.closeAll();
  }
}
