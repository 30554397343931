export const dataPositiveMock = [
    {
        "category": "Food",
        "number_of_repetitions": 150,
        "number_of_repetitions_variation": -20,
        "impact": 20,
        "impact_variation": 20,
    },
    {
        "category": "Hotel",
        "number_of_repetitions": 15,
        "number_of_repetitions_variation": 20,
        "impact": 10,
        "impact_variation": -3,
    },
    {
        "category": "Experience",
        "number_of_repetitions": 4,
        "number_of_repetitions_variation": -2,
        "impact": 2,
        "impact_variation": 50,
    },
    {
        "category": "Service",
        "number_of_repetitions": 350,
        "number_of_repetitions_variation": -20,
        "impact": 40,
        "impact_variation": 30,
    }
];

export const dataNegativeMock = [
    {
        "category": "Food",
        "number_of_repetitions": 150,
        "number_of_repetitions_variation": -20,
        "impact": 20,
        "impact_variation": 20,
    },
    {
        "category": "Hotel",
        "number_of_repetitions": 15,
        "number_of_repetitions_variation": 20,
        "impact": 10,
        "impact_variation": -3,
    },
    {
        "category": "Experience",
        "number_of_repetitions": 4,
        "number_of_repetitions_variation": -2,
        "impact": 2,
        "impact_variation": 50,
    },
    {
        "category": "Service",
        "number_of_repetitions": 350,
        "number_of_repetitions_variation": -20,
        "impact": 40,
        "impact_variation": 30,
    }
];
