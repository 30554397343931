import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading: boolean = false;
  invalidUserOrPasswd: boolean = false;
  errorLogin: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    if (localStorage.getItem('user')){
			this.router.navigateByUrl('');
		}

    this.initLoginForm();
  }


  initLoginForm() : void{
    this.loginForm = this.formBuilder.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320)
      ])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			],
      // otp: ['', Validators.compose([
			// 	Validators.required,
			//   Validators.pattern("^[0-9]*$"),
      //   Validators.minLength(6),
			// 	Validators.maxLength(6)
			// ])
			// ]
		});
  }

  onSearchChange(value: string){
    if(this.invalidUserOrPasswd){
      this.invalidUserOrPasswd = false
    }
  }

  submit(): void{
    const controls = this.loginForm.controls;
    this.loading = true;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
      this.loading = false;
			return;
		}


    // this.authService.login(controls.email.value, controls.password.value, controls.otp.value)
    this.authService.login(controls.email.value, controls.password.value)
      .pipe(
      tap(user => {
        //TODO NEW WAY TO CHECK
        console.log(user)

        if (!user) {
          this.errorLogin = true;
          this.loading = false;
        } else {
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('access', user.access)
          localStorage.setItem('refresh', user.refresh)
          this.invalidUserOrPasswd = false;
          this.loading = false;
          this.router.navigate(['/client']);
        }
    })).subscribe();
  }



}
