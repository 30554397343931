import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule} from '@angular/material/form-field';
import  {MatInputModule } from '@angular/material/input'
import { ReactiveFormsModule } from '@angular/forms';
import { AuthComponent } from './auth.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthService } from 'src/app/core/services/auth.service';

const routes: Routes = [
	{
		path: 'auth',
		component: AuthComponent,
    children: [
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
    },
      {
				path: 'login',
				component: LoginComponent
			}
    ]
	}
];

@NgModule({
  declarations: [
    AuthComponent,
		LoginComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [AuthComponent],
  providers: [
    AuthService
  ]
})
export class AuthModule { }
