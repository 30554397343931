import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToList'
})
export class EnumToListPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return Object.keys(value).filter(e => !isNaN(+e)).map(o => { return { id: +o, value: value[o] }; });
  }

}
