<div>
  <p class="text-primary title">{{data.schedule? "Edit Schedule" : "Create Schedule"}}</p>

  <form [formGroup]="createScheduleForm">

    <div class="row">

      <div class="col-4">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error
            *ngIf="createScheduleForm.get('name').invalid && createScheduleForm.get('name').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field>
          <mat-label>Emails</mat-label>
          <mat-select formControlName="email_addresses" multiple>
            <mat-option *ngFor="let email of emailList" [value]="email.id">{{email.email}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="createScheduleForm.get('email_addresses').invalid && createScheduleForm.get('email_addresses').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">

      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label>Search factor</mat-label>
          <mat-select formControlName="search_factor" autocomplete="off">
            <mat-option [value]="0">Streams</mat-option>
            <mat-option [value]="1">Keywords</mat-option>
            <mat-option [value]="2">Countries</mat-option>
            <mat-option [value]="3">Tours</mat-option>
          </mat-select>
          <mat-error
            *ngIf="createScheduleForm.get('search_factor').invalid && createScheduleForm.get('search_factor').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label>Format</mat-label>
          <mat-select formControlName="format" autocomplete="off">
            <mat-option [value]="0">PDF</mat-option>
            <mat-option [value]="1">xlsx</mat-option>
            <mat-option [value]="2">xls</mat-option>
          </mat-select>
          <mat-error
            *ngIf="createScheduleForm.get('format').invalid && createScheduleForm.get('format').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field appearance="outline">
          <mat-label>Date Range Export</mat-label>
          <mat-select formControlName="date_range_export" autocomplete="off">
            <mat-option [value]="0">Daily</mat-option>
            <mat-option [value]="1">Weekly</mat-option>
            <mat-option [value]="2">Monthly</mat-option>
          </mat-select>
          <mat-error
            *ngIf="createScheduleForm.get('date_range_export').invalid && createScheduleForm.get('date_range_export').errors['required']">This
            field is rquired</mat-error>
        </mat-form-field>
      </div>

    </div>

    <p class="text-primary title">Select Schedule</p>

    <div>

      <form [formGroup]="scheduleTimeForm">

        <mat-radio-group class="schedule-radio-group" [formControl]="scheduleTypeControl">
          <table>
            <tbody>
              <tr>

                <td>
                  <mat-radio-button [value]="0" class="schedule-radio-button">
                    From Monday to Friday
                  </mat-radio-button>

                </td>
              <tr>
                <td>
                  <mat-radio-button [value]="1" class="schedule-radio-button">
                    Every Week on
                  </mat-radio-button>
                </td>

                <td>
                  <mat-form-field appearance="outline">
                    <mat-select [disabled]="scheduleTypeControl.value !== 1" formControlName="dayOfWeek">
                      <mat-option value="1">Monday</mat-option>
                      <mat-option value="2">Tuesday</mat-option>
                      <mat-option value="3">Wednesday</mat-option>
                      <mat-option value="4">Thrusday</mat-option>
                      <mat-option value="5">Friday</mat-option>
                      <mat-option value="6">Saturday</mat-option>
                      <mat-option value="7">Sunday</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

              <tr>
                <td>
                  <mat-radio-button [value]="2" class="schedule-radio-button">
                    Every month on
                  </mat-radio-button>
                </td>

                <td>
                  <mat-form-field appearance="outline">
                    <mat-select [disabled]="scheduleTypeControl.value !== 2" formControlName="dayOfMonth">
                      <ng-container *ngFor="let date of dateList">
                        <mat-option [value]="date.id">{{date.value}}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>

            </tbody>
          </table>
        </mat-radio-group>

        <hr />

        <mat-form-field>
          <mat-label>Schedule Time</mat-label>
          <input matInput type="time" [formControl]="scheduleTimeControl" />
          <ng-container *ngIf="scheduleTimeControl.errors && scheduleTimeControl.touched">
            <mat-error>Time is requried</mat-error>
          </ng-container>
        </mat-form-field>


      </form>

    </div>

  </form>

  <div class="d-flex justify-content-start mt-3">
    <button (click)="createSchedule()" [ngClass]="{'loading pr-5': loading}"
      class="btn custom-btn text-white mt-2 mr-1">
      {{data.schedule? "Update" : "Create"}}
    </button>
    <button (click)="closePopup()" class="btn custom-btn-outline text-primary mt-2 mr-1">
      Close
    </button>
  </div>

</div>
